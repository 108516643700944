import { Form as AntForm, FormProps as AntFormProps } from 'antd';
import { observer } from 'mobx-react-lite';

export type FormProps = AntFormProps & { submitOnEnter?: boolean };

export const Form = observer(
    ({ submitOnEnter = true, ...props }: FormProps) => {
        return (
            <AntForm
                layout="vertical"
                {...props}
                onKeyDown={
                    submitOnEnter
                        ? async (e) => {
                              if (e.key === 'Enter') {
                                  e.stopPropagation();
                                  await props.form?.submit();
                              }
                          }
                        : undefined
                }
            />
        );
    },
);

import { EntityModelRepaymentScheduleListItem } from 'src/generated-api-client';
import { loanRepaymentsScheduleApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanRepaymentsScheduleStoreClass extends BasicStore<EntityModelRepaymentScheduleListItem> {
    api: BasicStoreApi<
        EntityModelRepaymentScheduleListItem,
        EntityModelRepaymentScheduleListItem,
        EntityModelRepaymentScheduleListItem,
        EntityModelRepaymentScheduleListItem
    > = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                loanRepaymentsScheduleApi.getRepaymentSchedules(
                    undefined,
                    undefined,
                    this.sorter.getSort(),
                    this.loanId,
                ),
            );

            // eslint-disable-next-line no-underscore-dangle
            return response._embedded?.repaymentScheduleListItems || [];
        },
    };

    sorter = new Sorter();

    constructor(private loanId: number) {
        super();
    }
}

export const LoanRepaymentsScheduleStore = new EntityProvider(
    LoanRepaymentsScheduleStoreClass,
);

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Router } from 'src/core/router/Router';
import { AppStore } from 'src/stores/AppStore/AppStore';

export const BrowserRouterBehavior = observer(() => {
    const navigate = useNavigate();
    useEffect(() => {
        Router.setNavigateFn(navigate);
        AppStore.isSetHistory = true; // TODO: refactor this;
        // AppStore.setHistory(history);
    }, []);

    return null;
});

export type DataIndex = string | number | (string | number)[];

export const getValueByPath = (source: any, path: string | string[]) => {
    if (Array.isArray(path)) {
        return path.reduce((obj: any, key) => obj[key], source);
    }

    return source[path];
};

export const getPropertyPathAsProp = (path?: DataIndex) => {
    if (Array.isArray(path)) {
        return path.join('.');
    }

    return path?.toString();
};

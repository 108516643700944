import { action, computed, observable } from 'mobx';
import { Moment } from 'moment';
import {
    EntityModelTransactionListItem,
    Transaction,
    TransactionBindingType,
    TransactionStatus,
    TransactionType,
} from 'src/generated-api-client';
import { transactionsApi } from 'src/services/apiServices';
import { DateHelper } from 'src/utils/DateHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TransactionsFilterCriteria = {
    applicationNumber?: string;
    companyName?: string;
    companyIdentity?: string;
    search?: string;
    transactionId?: string;
    amountFrom?: number;
    amountTo?: number;
    loanId?: number;
    showFreeTransactions?: boolean;
    statuses?: TransactionStatus[];
    types?: TransactionType[];
    updatedAtAfter?: Moment;
    updatedAtBefore?: Moment;
    repaymentScheduleDateAfter?: Moment;
    repaymentScheduleDateBefore?: Moment;
    bindingTypes?: TransactionBindingType[];
    comment?: string;
};

export class TransactionsStoreClass extends BasicStore<
    EntityModelTransactionListItem,
    TransactionsFilterCriteria,
    EntityModelTransactionListItem,
    EntityModelTransactionListItem,
    Transaction
> {
    api: BasicStoreApi<
        EntityModelTransactionListItem,
        EntityModelTransactionListItem,
        EntityModelTransactionListItem,
        Transaction
    > = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getTransactions(
                    this.pager.page,
                    this.pager.size,
                    this.sorter.getSort(),
                    this.filterCriteria.filter.applicationNumber,
                    this.filterCriteria.filter.companyName,
                    this.filterCriteria.filter.companyIdentity,
                    this.filterCriteria.filter.search,
                    this.filterCriteria.filter.transactionId,
                    this.filterCriteria.filter.amountFrom,
                    this.filterCriteria.filter.amountTo,
                    this.filterCriteria.filter.loanId,
                    this.filterCriteria.filter.showFreeTransactions ||
                        undefined,
                    this.filterCriteria.filter.statuses,
                    this.filterCriteria.filter.types,
                    DateHelper.formatISODate(
                        this.filterCriteria.filter.updatedAtAfter,
                    ),
                    DateHelper.formatISODate(
                        this.filterCriteria.filter.updatedAtBefore,
                    ),
                    DateHelper.formatISODate(
                        this.filterCriteria.filter.repaymentScheduleDateAfter,
                    ),
                    DateHelper.formatISODate(
                        this.filterCriteria.filter.repaymentScheduleDateBefore,
                    ),
                    this.filterCriteria.filter.bindingTypes,
                    this.filterCriteria.filter.comment,
                ),
            );

            this.pager.setTotal(response.page?.totalElements || 0);

            // eslint-disable-next-line no-underscore-dangle
            return response._embedded?.transactionListItems || [];
        },
        loadItem: async (id: number) => {
            // eslint-disable-next-line no-return-await
            return await RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getTransaction(id),
            );
        },
    };

    pager = new Pager();
    filterCriteria = new FilterCriteria<TransactionsFilterCriteria>(undefined, {
        excludeHasFilterKeys: ['search'],
    });

    sorter = new Sorter();

    @action async loadFreeTransactions() {
        this.filterCriteria.applyFilter({ showFreeTransactions: true });
        await this.loadList();
    }

    @observable selectedTransactions: Array<EntityModelTransactionListItem> =
        [];

    @action resetSelectedTransactions() {
        this.selectedTransactions = [];
    }

    @action selectTransaction(transaction: EntityModelTransactionListItem) {
        this.selectedTransactions.push(transaction);
    }

    @action deselectTransaction(transaction: EntityModelTransactionListItem) {
        this.selectedTransactions = this.selectedTransactions.filter(
            (tr) => tr.id !== transaction.id,
        );
    }

    @action clearSelected() {
        this.selectedTransactions = [];
    }
}

export const TransactionsStore = new TransactionsStoreClass();

/* eslint-disable max-lines */
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { TransactionStatus } from 'src/components/entities/TransactionStatus';
import { EnumView } from 'src/components/EnumView/EnumView';
import { CheckboxField } from 'src/components/Form/CheckboxField';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ListPage } from 'src/components/ListPage/ListPage';
import { TransactionBindingTypeIndicator } from 'src/components/TransactionBindingTypeIndicator/TransactionBindingTypeIndicator';
import {
    EntityModelTransactionListItem,
    TransactionStatus as TransactionStatusEnum,
} from 'src/generated-api-client';
import { TransactionDetailsDialog } from 'src/pages/TransactionsPage/TransactionDetailsDialog';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { StringHelper } from 'src/utils/StringHelper';
import styled from 'styled-components';

const DEFAULT_FILTER_STATUSES: TransactionStatusEnum[] = [
    TransactionStatusEnum.PENDING,
    TransactionStatusEnum.SUCCESS,
];

export const TransactionsPage = observer(() => {
    return (
        <StyledListPage
            title={t('Page.Transactions.Title')}
            store={TransactionsStore}
            columns={columns}
            onRowClick={(_e, record) => {
                TransactionDetailsDialog.render({ transactionId: record.id });
            }}
            beforeLoadList={() => {
                setupTransactionsFilter();
            }}
            header={
                <FilterForm
                    store={TransactionsStore}
                    search
                    searchPlaceholder={t(
                        'Page.Transactions.Filter.SearchPlaceholder',
                    )}
                    initialValues={{
                        statuses: DEFAULT_FILTER_STATUSES,
                    }}
                >
                    <TextField
                        name="applicationNumber"
                        label={t('Page.Transactions.Filter.ApplicationNumber')}
                    />
                    <TextField
                        name="companyName"
                        label={t('Page.Transactions.Filter.CompanyName')}
                    />
                    <TextField
                        name="companyIdentity"
                        label={t('Page.Transactions.Filter.CompanyIdentity')}
                    />
                    <TextField
                        name="comment"
                        label={t('Page.Transactions.Filter.Comment')}
                    />
                    <TextField
                        name="transactionId"
                        label={t('Page.Transactions.Filter.TransactionId')}
                    />
                    <NumberField
                        name="amountFrom"
                        label={t('Page.Transactions.Filter.AmountFrom')}
                    />
                    <NumberField
                        name="amountTo"
                        label={t('Page.Transactions.Filter.AmountTo')}
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="statuses"
                        enumName="TRANSACTION_STATUS"
                        label={t('Page.Transactions.Filter.Statuses')}
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="types"
                        enumName="TRANSACTION_TYPE"
                        label={t('Page.Transactions.Filter.Types')}
                    />
                    <EnumSelectField
                        mode="multiple"
                        name="bindingTypes"
                        enumName="TRANSACTION_BINDING_TYPE"
                        label={t('Page.Transactions.Filter.BindingTypes')}
                    />
                    <DatePickerField
                        name="updatedAtAfter"
                        label={t('Page.Transactions.Filter.UpdatedAtAfter')}
                    />
                    <DatePickerField
                        name="updatedAtBefore"
                        label={t('Page.Transactions.Filter.UpdatedAtBefore')}
                    />

                    <DatePickerField
                        name="repaymentScheduleDateAfter"
                        label={t(
                            'Page.Transactions.Filter.RepaymentScheduleDateAfter',
                        )}
                    />
                    <DatePickerField
                        name="repaymentScheduleDateBefore"
                        label={t(
                            'Page.Transactions.Filter.RepaymentScheduleDateBefore',
                        )}
                    />
                    <CheckboxField name="showFreeTransactions" label=" ">
                        {t('Page.Transactions.Filter.ShowFreeTransactions')}
                    </CheckboxField>
                </FilterForm>
            }
        />
    );
});

const StyledListPage = styled(ListPage)`
    tr.ant-table-row {
        &.green {
            td {
                background-color: #e9fcdb;
            }

            &:hover {
                td {
                    background-color: #ddffc3 !important;
                }
            }
        }

        &.orange {
            td {
                background-color: #faebc5;
            }

            &:hover {
                td {
                    background-color: #ffe5ad !important;
                }
            }
        }
    }
`;

const columns: ColumnsType<EntityModelTransactionListItem> = [
    {
        key: 'transactionId',
        dataIndex: 'transactionId',
        title: <Trans i18nKey="Page.Transactions.Items.Id" />,
        render: (value, record) => {
            return (
                <Space size={10}>
                    <TransactionBindingTypeIndicator transaction={record} />

                    <DataView
                        hideLabel
                        value={value}
                        format={(value) =>
                            StringHelper.sliceFirstWithEllipsis(value, 6)
                        }
                        tooltip={value}
                    />
                </Space>
            );
        },
        width: 120,
    },
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: <Trans i18nKey="Page.Transactions.Items.CompanyName" />,
        render: (value) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) =>
                        StringHelper.sliceFirstWithEllipsis(value, 10)
                    }
                    tooltip={value}
                />
            );
        },
        width: 150,
    },
    {
        key: 'companyIdentity',
        dataIndex: 'companyIdentity',
        title: <Trans i18nKey="Page.Transactions.Items.CompanyIdentity" />,
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
    },
    {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: <Trans i18nKey="Page.Transactions.Items.Date" />,
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Page.Transactions.Items.Status" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    format={() => <TransactionStatus status={value} />}
                    hideLabel
                />
            );
        },
        sorter: true,
        width: 120,
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Page.Transactions.Items.Type" />,
        sorter: true,
        render: (value) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => (
                        <EnumView enumName="TRANSACTION_TYPE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Page.Transactions.Items.Amount" />,
        render: (value) => {
            return <DataView hideLabel value={value} format="money" />;
        },
        sorter: true,
    },
    {
        key: 'comment',
        dataIndex: 'comment',
        title: <Trans i18nKey="Page.Transactions.Items.Comment" />,
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
    },

    {
        key: 'applicationNumber',
        dataIndex: 'applicationNumber',
        title: <Trans i18nKey="Page.Transactions.Items.LoanId" />,
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
        sorter: true,
    },
    {
        key: 'repaymentScheduleDate',
        dataIndex: 'repaymentScheduleDate',
        title: <Trans i18nKey="Page.Transactions.Items.ScheduleConnection" />,
        render: (value) => {
            return <DataView hideLabel value={value} format="date" />;
        },
        sorter: true,
    },
];

function setupTransactionsFilter() {
    TransactionsStore.filterCriteria.setDefaultFilter({
        statuses: DEFAULT_FILTER_STATUSES,
    });
    TransactionsStore.filterCriteria.setExcludedFilterKeysFromCalculatedHasValue(
        ['search'],
    );
    TransactionsStore.filterCriteria.resetFilter();
}

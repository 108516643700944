import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import { RouteDefinition } from 'src/stores/RouterStore/RouterStore.types';
import styled from 'styled-components';

export const AppMenu = observer(() => {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();

    useEffect(() => {
        const keys = RouterStore.menuItems
            .filter((route) => {
                return location.pathname.includes(route.path!);
            })
            .map((route) => route?.path!);

        setSelectedKeys(keys);
    }, [location.pathname]);

    return (
        <StyledMenu
            selectedKeys={selectedKeys}
            mode="horizontal"
            items={RouterStore.menuItems.map((route) => getMenuItem(route))}
        />
    );
});

const getMenuItem = (route: RouteDefinition): ItemType => {
    return {
        label: <Link to={route.path!}>{route.label}</Link>,
        key: route.path!,
    };
};

const StyledMenu = styled(Menu)`
    flex: 1;
    background: transparent;
    padding-left: 30px;

    .ant-menu-title-content {
        padding: 0 15px;
    }
`;

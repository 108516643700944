import Keycloak from 'keycloak-js';
import {
    KC_AUTH_TOKENS,
    KeycloakAuthTokens,
    KeycloakBehaviorStrategy,
} from 'src/stores/AuthStore/KeycloakBehaviorStrategy';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';

export class TokensKeycloakBehaviorStrategy extends KeycloakBehaviorStrategy {
    async authenticate() {
        const authTokens = this.readTokens();

        return (
            (await this.keycloak?.init({
                ...authTokens,
                checkLoginIframe: false,
            })) || false
        );
    }

    async logout() {
        this.clearTokens();

        await this.keycloak?.login({ kcLocale: 'en' } as any);
    }

    constructor(keycloak: Keycloak) {
        super(keycloak);

        this.keycloak.onAuthRefreshSuccess = () => {
            this.storeTokens();
        };
    }

    private readTokens() {
        return LocalStoreHelper.getItem<KeycloakAuthTokens>(KC_AUTH_TOKENS);
    }

    private storeTokens() {
        return LocalStoreHelper.setItem(KC_AUTH_TOKENS, {
            token: this.keycloak.token,
            refreshToken: this.keycloak.refreshToken,
        });
    }

    private clearTokens() {
        LocalStoreHelper.clearItem(KC_AUTH_TOKENS);
    }
}

import { KeycloakBehaviorStrategy } from 'src/stores/AuthStore/KeycloakBehaviorStrategy';

export class CheckSSOKeycloakBehaviorStrategy extends KeycloakBehaviorStrategy {
    async authenticate() {
        return (
            (await this.keycloak?.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                pkceMethod: 'S256',
            })) || false
        );
    }

    async logout() {
        await this.keycloak?.logout();
    }
}

import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const MasterDetailsAsideItem = (
    props: React.PropsWithoutRef<NavLinkProps> &
        React.RefAttributes<HTMLAnchorElement>,
) => {
    return (
        <StyledNavLink
            {...props}
            onClick={(e) => {
                if (
                    (e.currentTarget as HTMLElement).classList.contains(
                        'active',
                    )
                ) {
                    e.preventDefault();
                }
            }}
        />
    );
};

export const StyledNavLink = styled(NavLink)`
    display: block;
    padding: 14px 24px;
    background: white;
    border-right: 2px solid white;
    font-size: 15px;

    &:hover {
        background: #f5f6f7;
        border-color: #f5f6f7;
        text-decoration: none;
    }

    &.active {
        background: #f5f6f7;
        border-color: #0a65ed;
    }
`;

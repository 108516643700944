import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Localization } from 'src/generated-api-client';
import { EnumName, EnumStore } from 'src/stores/EnumStore/EnumStore';

export type EnumViewProps = {
    enumName: EnumName;
    value?: any;
};
export const EnumView = observer(({ enumName, value }: EnumViewProps) => {
    const store = EnumStore.getInstance(enumName);
    useEffect(() => {
        if (store.listLoader.countLoads === 0) {
            store.loadList();
        }
    }, [enumName]);

    const item = store.list.find((x) => x.key === value);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{item?.l10n?.[i18next.language as keyof Localization] || null}</>;
});

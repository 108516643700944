import { EllipsisOutlined } from '@ant-design/icons';
import {
    Button,
    Card as AntCard,
    CardProps as AntCardProps,
    Dropdown,
    Menu,
    Space,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

type CardProps = AntCardProps & {
    // eslint-disable-next-line react/require-default-props
    menuActions?: ItemType[];
    // eslint-disable-next-line react/require-default-props
    isLoading?: boolean;
};

export const Card = ({
    children,
    menuActions,
    extra,
    isLoading,
    ...props
}: CardProps) => {
    return (
        <StyledCard
            extra={
                <Space size={16}>
                    <div>{extra}</div>
                    <div>
                        {menuActions && (
                            <Dropdown
                                overlay={<Menu items={menuActions} />}
                                trigger={['hover']}
                                placement="bottomRight"
                                disabled={isLoading}
                            >
                                <StyledIconButton icon={<EllipsisOutlined />} />
                            </Dropdown>
                        )}
                    </div>
                </Space>
            }
            {...props}
        >
            {children}
            {isLoading && <StyledSpinner />}
        </StyledCard>
    );
};

const StyledSpinner = styled(Spinner)`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
`;

const StyledCard = styled(AntCard)`
    border: none;
    border-radius: 8px;
    margin-bottom: 24px;

    .ant-card-head {
        border-bottom: none;
        padding: 0;
        margin-bottom: 8px;
        min-height: unset;
        height: fit-content;

        .ant-card-head-title {
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;

            letter-spacing: 0.5px;

            color: #4d6082;

            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
        }

        .ant-card-extra {
            padding: 0;
        }
    }

    .ant-card-body {
        padding: 0;
    }

    .ant-row + .ant-row {
        border-top: 1px solid #dfe0eb;
        padding-top: 15px;
    }
`;

const StyledIconButton = styled(Button)`
    border: none;
    min-width: fit-content;
    padding: 0 8px;
    margin-right: -12px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
`;

import { Input as AntInput, InputProps as AntInputProps } from 'antd';
import styled from 'styled-components';

type InputProps = AntInputProps & {
    // eslint-disable-next-line react/require-default-props
    value?: string;
    // eslint-disable-next-line react/require-default-props
    onChange?: (_value: string) => void;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    max?: number;
    // eslint-disable-next-line react/require-default-props
    onlyNumbers?: boolean;
    // eslint-disable-next-line react/require-default-props
    addonAfter?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    placeholder?: any;

    // eslint-disable-next-line react/require-default-props
    extra?: React.ReactNode;
};

const ONLY_NUMBERS_PATTERN = /^\d*$/;

export const Input = ({
    onChange,
    value,
    onlyNumbers,
    maxLength,
    max,
    extra,
    ...props
}: InputProps) => {
    const validateNumbers = (v: string) => {
        if (ONLY_NUMBERS_PATTERN.test(v)) {
            if (max) {
                max >= Number(v) ? onChange?.(v) : onChange?.(value || '');
            } else {
                onChange?.(v);
            }
        } else {
            onChange?.(value || '');
        }
    };

    return (
        <StyledGroup compact>
            <AntInput
                {...props}
                value={value}
                maxLength={maxLength}
                onChange={(e) => {
                    const v = e.target.value;
                    if (onlyNumbers) {
                        validateNumbers(v);
                    } else {
                        onChange?.(v);
                    }
                }}
            />
            {extra}
        </StyledGroup>
    );
};

const StyledGroup = styled(AntInput.Group)`
    &.ant-input-group.ant-input-group-compact {
        display: flex;
    }
`;

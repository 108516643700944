import axios from 'axios';
import { Env } from 'src/core/Environment';
// import { Env } from 'src/core/Environment';
import {
    Configuration,
    EnumsControllerApiFactory,
    EventControllerApiFactory,
    LoanControllerApiFactory,
    NotificationControllerApiFactory,
    RepaymentScheduleControllerApiFactory,
    TransactionControllerApiFactory,
    UiSettingsControllerApiFactory,
} from 'src/generated-api-client';

axios.defaults.withCredentials = true;

const apiConfig: Configuration | undefined = undefined;

export const uiSettingsApi = UiSettingsControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const authApi = {} as any; // TODO: add mock implementation of authApi

export const loansApi = LoanControllerApiFactory(apiConfig, Env.basePath);
export const loanRepaymentsScheduleApi = RepaymentScheduleControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loanNotificationsApi = NotificationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const transactionsApi = TransactionControllerApiFactory(
    apiConfig,
    Env.basePath,
);
export const enumApi = EnumsControllerApiFactory(apiConfig, Env.basePath);
export const eventsApi = EventControllerApiFactory(apiConfig, Env.basePath);

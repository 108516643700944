export class StringHelper {
    static sliceFirst(value: string | undefined, count: number) {
        if (value && value.length > count) {
            return `${value?.slice(0, count)}`;
        }

        return value;
    }

    static sliceFirstWithEllipsis(value: string | undefined, count: number) {
        if (value && value.length > count) {
            return `${value?.slice(0, count)}…`;
        }

        return value;
    }
}

import { Env } from 'src/core/Environment';

export const RoutePaths = {
    index: `${Env.basePath}/`,

    profile: `${Env.basePath}/profile`,

    loans: `${Env.basePath}/loans`,
    loanDetails: `${Env.basePath}/loans/:loanId`,

    transactions: `${Env.basePath}/transactions/`,
    transactionDetails: `${Env.basePath}/transactions/:transactionId`,

    authLogin: `${Env.basePath}/auth/login`,
    authForgotPassword: `${Env.basePath}/auth/forgot-password`,
    authRecoveryPassword: `${Env.basePath}/auth/recovery-password`,
    authRecoveryExpiredPassword: `${Env.basePath}/auth/recovery-expired-password`,
    authRequestToRecoveryExpiredPassword: `${Env.basePath}/auth/request-to-recovery-expired-password`,
    authRegistrationSetPassword: `${Env.basePath}/auth/confirm-registration`,
    authResetPassword: `${Env.basePath}/profile/reset-password`,
};

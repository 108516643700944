import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityModelRepaymentScheduleListItem } from 'src/generated-api-client';
import { LoanRepaymentsScheduleStore } from 'src/stores/LoanRepaymentsScheduleStore/LoanRepaymentsScheduleStore';

export type RepaymentScheduleTabProps = {
    loanId: number;
};
export const RepaymentScheduleTab = observer(
    ({ loanId }: RepaymentScheduleTabProps) => {
        const store = LoanRepaymentsScheduleStore.getInstance(loanId);
        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [loanId]);

        return (
            <Card
                title={t(
                    'Loan.RepaymentScheduleTab.Title',
                    'Repayments schedule',
                )}
            >
                <ListView
                    store={store}
                    columns={columns}
                    loading={store.listLoader.isLoading}
                />
            </Card>
        );
    },
);

const columns: ColumnsType<EntityModelRepaymentScheduleListItem> = [
    {
        key: 'date',
        dataIndex: 'date',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.Repayment" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="date" />;
        },
        sorter: true,
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.PaymentAmount" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },

    {
        key: 'principal',
        dataIndex: 'principal',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.Principal" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'interest',
        dataIndex: 'interest',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.Interest" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'outstandingBalance',
        dataIndex: 'outstandingBalance',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.Outstanding" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },
    {
        key: 'painAmount',
        dataIndex: 'painAmount',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.PaidAmount" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="money" />;
        },
    },

    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Loan.RepaymentScheduleTab.Status" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
        sorter: true,
    },
];

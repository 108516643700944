import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage, BasePageProps } from 'src/components/BasePage/BasePage';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import styled from 'styled-components';

export type MasterDetailsPageProps<T extends Entity> = BasePageProps & {
    store: BasicStore<T>;
    asidePanel: React.ReactNode;
};

export const MasterDetailsPage: React.FC<MasterDetailsPageProps<any>> =
    observer(({ children, asidePanel, store, ...props }) => {
        // const queryParams = Router.getQuerySearchParams();
        // const filtersFromUrl = UrlHelper.getFiltersFromUrl(queryParams);
        useEffect(() => {
            // if (Object.keys(filtersFromUrl).length) {
            //     store.filterCriteria?.applyFilter(filtersFromUrl);
            // }
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, []);

        return (
            <StyledBasePage {...props}>
                {asidePanel}
                <StyledDetails>{children}</StyledDetails>
            </StyledBasePage>
        );
    });

const StyledBasePage = styled(BasePage)`
    overflow: hidden;
    display: flex;
    position: relative;
    padding-top: 0;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
`;

const StyledDetails = styled.section`
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 24px;
`;

import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import styled from 'styled-components';

export const LanguageSwitcher = observer(() => {
    const [visible, setVisible] = useState(false);
    const { t, i18n } = useTranslation();
    const closeMenu = () => {
        setVisible(false);
    };

    if (!SettingsStore.hasFewLocales) {
        return null;
    }

    return (
        <Dropdown
            overlay={<Locales closeMenu={closeMenu} />}
            onVisibleChange={(isVisible) => setVisible(isVisible)}
            visible={visible}
        >
            <StyledButton>
                {t(`Component.LanguageSwitcher.Locale.Short.${i18n.language}`)}
            </StyledButton>
        </Dropdown>
    );
});

type LocalesProps = { closeMenu: () => void };
const Locales = observer(({ closeMenu }: LocalesProps) => {
    const { t } = useTranslation();

    return (
        <StyledMenu
            activeKey={SettingsStore.currentLocale}
            onClick={(e) => {
                e.domEvent.preventDefault();
                closeMenu();
            }}
        >
            {SettingsStore.supportedLocales.map((locale) => {
                return (
                    <Menu.Item
                        key={locale}
                        onClick={() => SettingsStore.setLocale(locale)}
                    >
                        {t(`Component.LanguageSwitcher.Locale.Long.${locale}`)}
                    </Menu.Item>
                );
            })}
        </StyledMenu>
    );
});

const StyledButton = styled(Button)`
    font-size: 16px;
    color: #a4a6a8;
    border: none;
    box-shadow: none;
    background: transparent;
    margin-right: 5px;

    &:focus,
    &:hover {
        color: white;
        background: transparent;
    }
`;
const StyledMenu = styled(Menu)`
    flex: 1;
    border-right: none;
    box-shadow: 0px 4px 4px rgb(222 222 222 / 25%) !important;

    .ant-menu-item {
        color: #8b959e;
        &:after {
            display: none;
        }

        + .ant-menu-item {
            margin-left: 1px;
        }

        &:hover {
            color: black;
        }

        &.ant-menu-item-selected,
        &:active {
            background-color: transparent;
        }
    }
`;

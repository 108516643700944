import { TabPaneProps, Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';
import React from 'react';
import { useSearchParams } from 'src/hooks/useSeachParams';

const { TabPane } = AntTabs;

export type TabDefinition = Omit<TabPaneProps, 'tab'> & {
    tabKey: string;
    title: React.ReactNode;
    Component: React.ReactNode;
    visible?: boolean;
};

type TabsProps = AntTabsProps & {
    tabs: TabDefinition[];
    // eslint-disable-next-line react/require-default-props
    urlSearchActiveTabKey?: string;
};

const DEFAULT_TAB_SEARCH_KEY = 'tab';

export const Tabs = ({
    tabs,
    urlSearchActiveTabKey = DEFAULT_TAB_SEARCH_KEY,
    ...props
}: TabsProps) => {
    const searchParams = useSearchParams();
    const activeTabKey = searchParams.params[urlSearchActiveTabKey] as string;

    const tabPanes = tabs
        .filter((tab) => tab.visible !== false)
        .map(({ title, tabKey, Component, ...paneProps }) => {
            return (
                <TabPane {...paneProps} key={tabKey} tab={title}>
                    {Component}
                </TabPane>
            );
        });

    return (
        <AntTabs
            {...props}
            destroyInactiveTabPane
            defaultActiveKey={activeTabKey}
            onChange={(activeKey: string) => {
                searchParams.applySearchParams({
                    [urlSearchActiveTabKey]: activeKey,
                });
            }}
        >
            {tabPanes}
        </AntTabs>
    );
};

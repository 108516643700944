/* eslint-disable max-lines */
import {
    CheckCircleFilled,
    InfoCircleFilled,
    WarningOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { Loan, LoanStatus, MessageTypeEnum } from 'src/generated-api-client';
import { ConnectFreeTransactionsDialog } from 'src/pages/LoanPage/ConnectFreeTransactionsDialog';
import styled from 'styled-components';

export type LoanPaymentInformationProps = { loan?: Loan };
export const LoanPaymentInformation = observer(
    ({ loan }: LoanPaymentInformationProps) => {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                {loan?.messages?.map((message, index) => {
                    if (message.type === MessageTypeEnum.ALL_PAYMENTS_FILLED) {
                        return (
                            <AllPaymentsFilledMessage key={index} loan={loan} />
                        );
                    }

                    if (message.type === MessageTypeEnum.DUE_DATE) {
                        return <DueDateMessage key={index} loan={loan} />;
                    }

                    if (message.type === MessageTypeEnum.PAYMENT_OVERDUE) {
                        return <OverdueMessage key={index} loan={loan} />;
                    }

                    return null;
                })}
            </>
        );
    },
);

const allowedLoanStatusesToCreateFillOperation = [
    LoanStatus.ACTIVE,
    LoanStatus.OVERDUE,
];

const AllPaymentsFilledMessage = observer(
    ({ loan }: LoanPaymentInformationProps) => {
        return (
            <StyledCard
                title={
                    <StyledAllPaymentsFilledMessageTitle>
                        <StyledOkIcon />{' '}
                        {t('Page.LoanDetails.Message.AllPaymentsFilled')}
                    </StyledAllPaymentsFilledMessageTitle>
                }
            >
                <CreateFillOperation loan={loan} />
            </StyledCard>
        );
    },
);

const DueDateMessage = observer(({ loan }: LoanPaymentInformationProps) => {
    return (
        <StyledCard
            title={
                <StyledDueMessageTitle>
                    <InfoCircleFilled /> {t('Page.LoanDetails.Message.DueDate')}
                </StyledDueMessageTitle>
            }
        >
            <Space size={16} align="center" direction="horizontal">
                <StyledDueDateDataView
                    value={loan?.overdue?.amount}
                    format="money"
                    hideLabel
                />
                <CreateFillOperation loan={loan} />
            </Space>
        </StyledCard>
    );
});

const OverdueMessage = observer(({ loan }: LoanPaymentInformationProps) => {
    return (
        <StyledCard
            title={
                <StyledOverdueMessageTitle>
                    <InfoCircleFilled />
                    {t('Page.LoanDetails.Message.PaymentOverdue')}
                </StyledOverdueMessageTitle>
            }
        >
            <Space size={16} align="center" direction="horizontal">
                <StyledOverdueDataView
                    value={loan?.overdue?.amount}
                    format="money"
                    hideLabel
                />
                <CreateFillOperation loan={loan} />
            </Space>
        </StyledCard>
    );
});
export type CreateFillOperationProps = { loan?: Loan };

const CreateFillOperation = observer(({ loan }: CreateFillOperationProps) => {
    const disabledFillTransactions = !loan?.transactions?.freeTransactionCount;
    const showCreateFillOperation =
        loan?.status &&
        allowedLoanStatusesToCreateFillOperation.includes(loan?.status) &&
        loan?.transactions?.freeTransactionCount &&
        loan?.transactions?.freeTransactionCount > 0 &&
        Boolean(loan?.nextPayment?.status);

    if (!showCreateFillOperation) {
        return null;
    }

    return (
        <Space size={16} align="center" direction="horizontal">
            <FillButton
                disabled={disabledFillTransactions}
                onClick={() => {
                    ConnectFreeTransactionsDialog.render({ loan });
                }}
            >
                {t(
                    'Page.LoanDetails.Message.PaymentOverdue.CreateFillOperation',
                    'Create fill operation',
                )}
            </FillButton>
            <StyledCountFreeTransactions>
                <StyledWarningIcon />{' '}
                {t(
                    'Page.LoanDetails.Message.PaymentOverdue.CountFreeTransactions',
                    { count: loan?.transactions?.freeTransactionCount },
                )}
            </StyledCountFreeTransactions>
        </Space>
    );
});

const StyledCountFreeTransactions = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 105%;

    color: #5f5f5f;
`;
const StyledCard = styled(Card)`
    background: #f5f6f7;
    border-radius: 8px;
    padding: 24px;

    .ant-card-head {
        margin-bottom: 0;
    }
`;

const StyledOkIcon = styled(CheckCircleFilled)`
    color: #30b400;
`;

const StyledAllPaymentsFilledMessageTitle = styled(Space)`
    color: #30b400;
    margin-bottom: 16px;

    * {
        font-weight: 700;
        font-size: 20px;
        line-height: 105%;
    }
`;
const StyledDueMessageTitle = styled(Space)`
    color: #ff7a00;
    margin-bottom: 16px;
    * {
        font-weight: 700;
        font-size: 20px;
        line-height: 105%;
    }
`;
const StyledOverdueMessageTitle = styled(Space)`
    color: #ff006b;
    margin-bottom: 16px;
    * {
        font-weight: 700;
        font-size: 20px;
        line-height: 105%;
    }
`;
const FillButton = styled(Button)`
    border: none;
    color: white;
    background: #ff006b;
    border-radius: 6px;

    line-height: 165%;
    height: 46px;
    padding-left: 25px;
    padding-right: 25px;

    * {
        font-weight: 700;
        font-size: 16px;
    }

    &:hover,
    &:active,
    &:focus {
        color: white;
        background: #ff006b;
    }

    &:disabled {
        background: #a0a0a0;
        color: white;

        &:hover,
        &:active {
            background: #a0a0a0;
            color: white;
        }
    }
`;

const StyledWarningIcon = styled(WarningOutlined)`
    svg {
        font-size: 18px;
    }
    color: #ff7a00;
`;
const StyledOverdueDataView = styled(DataView)`
    margin-bottom: 0;
    min-width: 150px;

    .app-data-view__value * {
        font-weight: 700;
        font-size: 28px;
        line-height: 105%;
        color: #c70053;
    }
`;
const StyledDueDateDataView = styled(DataView)`
    margin-bottom: 0;
    min-width: 150px;

    .app-data-view__value * {
        font-weight: 700;
        font-size: 28px;
        line-height: 105%;
        color: #ff7a00;
    }
`;

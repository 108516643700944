import {
    InputNumber as AntInputNumber,
    InputNumberProps as AntInputNumberProps,
} from 'antd';
import styled from 'styled-components';

type InputNumberProps = AntInputNumberProps & {
    // eslint-disable-next-line react/require-default-props
    value?: string;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    onChange?: (value: string | number | undefined) => void;
};

export const InputNumber = ({
    onChange,
    value,
    ...props
}: InputNumberProps) => {
    return (
        <StyledInputNumber
            {...props}
            value={value}
            onChange={(value) => {
                const isValidNumber = Number.isFinite(Number(value));
                onChange?.(isValidNumber ? value : null);
            }}
        />
    );
};

const StyledInputNumber = styled(AntInputNumber)`
    width: 100%;
`;

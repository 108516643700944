import { DatePicker, Form, FormItemProps } from 'antd';
import React from 'react';
import { DEFAULT_DATE_FORMAT } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type DatePickerFieldProps = FormItemProps & {
    name: string;
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    format?: string;
    // eslint-disable-next-line react/require-default-props,@typescript-eslint/no-unused-vars
    disabledDate?: (currentDate: any) => boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
};

export const DatePickerField = ({
    name,
    label,
    format = DEFAULT_DATE_FORMAT,
    disabledDate,
    disabled,
    ...props
}: DatePickerFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <DatePickerStyled
                allowClear
                format={format}
                disabledDate={disabledDate}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export const DatePickerStyled = styled(DatePicker)`
    width: 100%;
    .ant-picker-input {
        display: flex;
    }
`;

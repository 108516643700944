import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EnumView } from 'src/components/EnumView/EnumView';
import {
    EntityModelTransactionListItem,
    TransactionBindingType,
} from 'src/generated-api-client';
import styled from 'styled-components';

export type TransactionBindingTypeIndicatorProps = {
    transaction: EntityModelTransactionListItem;
};
export const TransactionBindingTypeIndicator = observer(
    ({ transaction }: TransactionBindingTypeIndicatorProps) => {
        return (
            <Tooltip
                title={
                    <EnumView
                        enumName="TRANSACTION_BINDING_TYPE"
                        value={transaction.bindingType}
                    />
                }
            >
                <BindingIndicator bindingType={transaction.bindingType} />
            </Tooltip>
        );
    },
);

const getBindingColor = (binding: TransactionBindingType) => {
    if (binding === TransactionBindingType.AUTOMATIC) {
        return '#C2F9BB';
    }

    if (binding === TransactionBindingType.MANUAL) {
        return '#A1E5FB';
    }

    return '#FCB3BC';
};
const BindingIndicator = styled.div<{ bindingType: TransactionBindingType }>`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${(props) => getBindingColor(props.bindingType)};
`;

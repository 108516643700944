import { Col, Form, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { generatePath, useParams } from 'react-router-dom';
import { DataView } from 'src/components/DataView/DataView';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { TextField } from 'src/components/Form/TextField';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsAsideItem } from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { EntityModelLoanListItem } from 'src/generated-api-client';
import { LoanPage } from 'src/pages/LoanPage/LoanPage';
import { LoanStatus } from 'src/pages/LoanPage/LoanStatus';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export const LoansPage = observer(() => {
    const { loanId } = useParams();

    return (
        <MasterDetailsPage
            asidePanel={
                <MasterDetailsAside
                    store={LoansStore}
                    renderItem={(item: EntityModelLoanListItem) => {
                        return (
                            <MasterDetailsAsideItem
                                to={generatePath(RoutePaths.loanDetails, {
                                    loanId: item.id,
                                })}
                            >
                                <Row gutter={16}>
                                    <Col>
                                        <DataView
                                            value={item.applicationNumber}
                                            hideLabel
                                        />
                                    </Col>
                                    <StyledCompanyNameCol>
                                        <DataView
                                            value={item.companyInfo.name}
                                            hideLabel
                                            format="string"
                                            tooltip={item.companyInfo.name}
                                        />
                                    </StyledCompanyNameCol>
                                    <Col>
                                        <DataView
                                            value={item.outstandingBalance}
                                            hideLabel
                                            format="money"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col flex={1}>
                                        <LoanStatus status={item.status} />
                                    </Col>
                                    <Col>
                                        <DataView
                                            value={item.companyInfo.identity}
                                            hideLabel
                                            format="string"
                                        />
                                    </Col>
                                </Row>
                            </MasterDetailsAsideItem>
                        );
                    }}
                    header={<LoansPageAsideHeader />}
                />
            }
            store={LoansStore}
        >
            <LoanPage key={loanId} />
        </MasterDetailsPage>
    );
});

const LoansPageAsideHeader = observer(() => {
    return (
        <SearchPanel
            store={LoansStore}
            placeholder={t(
                'Page.Loans.Filter.Placeholder',
                'Company, UTR/CRN or Loan №',
            )}
            renderPanel={(props) => <FilterPanel {...props} />}
        />
    );
});

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ createdAt: [] }}
            onFinish={(values) => {
                const { createdAt } = values;
                const [createdAtAfter, createdAtBefore] = createdAt || [
                    undefined,
                    undefined,
                ];

                LoansStore.filterCriteria?.applyFilter({
                    ...values,
                    createdAtAfter: DateHelper.formatISODate(createdAtAfter),
                    createdAtBefore: DateHelper.formatISODate(createdAtBefore),
                    status: values.status,
                });
                LoansStore.pager.reset();
                LoansStore.loadList();
            }}
        >
            <TextField
                name="applicationNumber"
                label={t('Page.Loans.Filter.ApplicationNumber', 'Loan №')}
            />
            <TextField
                name="identity"
                label={t('Page.Loans.Filter.CompanyIdentity', 'UTR/CRN')}
            />
            <EnumSelectField
                mode="multiple"
                name="status"
                enumName="LOAN_STATUS"
                label={t('Page.Loans.Filter.Status')}
            />
            <Form.Item
                label={t('Page.Loans.Filter.CreatedAt', 'Created at')}
                name="createdAt"
            >
                <DateRangePicker
                    placeholder={[
                        t('Component.DatePicker.Start'),
                        t('Component.DatePicker.Start'),
                    ]}
                />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledCompanyNameCol = styled(Col)`
    flex: 1;
    overflow: hidden;
    .app-data-view__value{
        width: 100%:
        text-overflow: ellipsis;
        .ant-space{
            width: 100%;

            .ant-space-item{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
`;

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 46px;
        border-radius: 8px;
    }
`;

// type LoanItemProps = { id: number};

// const LoanItem = observer(() => {
//     const {loanId} = useParams<{loanId: string}>();
//     useEffect(() => {
//         if(loanId){
//             LoansStore.loadItem(Number(loanId))
//         }
//     }, [loanId])

//     if(LoansStore.itemLoader.isLoading){
//         return <Spinner/>
//     }

//     const loan = LoansStore.currentItem;

//     return <div>
//         <Row gutter={16}>
//             <Col flex={1}>
//                 <Typography.Title level={4}>
//                     {loan?.companyInfo.name}
//                 </Typography.Title>
//                 <DataView value={loan?.companyInfo.identity} format={'string'} label={t('Loan.CompanyInfo.Identity', 'YTR/СRN')}/>
//             </Col>
//             <Col>
//                 <DataView value={getName(loan?.memberInfo)} format={'string'} hideLabel/>
//                 <DataView value={loan?.memberInfo.email} format={'mail'} hideLabel/>
//                 <DataView value={loan?.memberInfo.phone} format={'tel'} hideLabel/>
//             </Col>
//             <Col>
//                 <Typography.Title level={4}>
//                     {loan?.id}
//                 </Typography.Title>
//             </Col>
//             <Col>
//                 <Typography.Title level={4}>
//                     {loan?.status}
//                 </Typography.Title>
//             </Col>

//         </Row>

//         <Row gutter={16}>
//             <Col xs={6}>
//                 <DataView value={undefined} label={<>{t('Page.LoanDetails.NextPayment', 'Next payment by')}</>}></DataView>
//             </Col>
//             <Col xs={6}>
//                 <DataView value={loan?.term} label={t('Page.LoanDetails.Term', 'Loan term', {count: loan?.term})}></DataView>
//             </Col>
//             <Col xs={6}>
//                 <DataView value={loan?.loanAmount} label={t('Page.LoanDetails.Amount', 'Loan amount')}></DataView>
//             </Col>
//             <Col xs={6}>
//                 <DataView value={undefined} label={t('Page.LoanDetails.InterestRate', 'Interest rate')} format="percent"></DataView>
//             </Col>
//         </Row>
//         <Tabs tabs={[]}></Tabs>
//     </div>
// })

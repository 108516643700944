// eslint-disable-next-line simple-import-sort/sort
import { Spin } from 'antd';
import 'src/styles/index.scss';
import 'antd/dist/antd.css';
import { observer } from 'mobx-react-lite';
import { StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'src/App.css';
import { Spinner } from 'src/components/Spinner/Spinner';
import { AppLayout } from 'src/containers/AppLayout/AppLayout';
import { BrowserRouterBehavior } from 'src/core/router/BrowserRouterBehavior';
import 'src/services/apiInterceptors';
import { AppStore } from 'src/stores/AppStore/AppStore';

Spin.setDefaultIndicator(<Spinner />);

const App = observer(() => {
    useEffect(() => {
        AppStore.init();
    }, []);

    return (
        <StrictMode>
            <BrowserRouter>
                <BrowserRouterBehavior />
                <AppLayout />
            </BrowserRouter>
        </StrictMode>
    );
});

export default App;

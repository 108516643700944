/* eslint-disable max-lines */
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { TransactionStatus } from 'src/components/entities/TransactionStatus';
import { EnumView } from 'src/components/EnumView/EnumView';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { EnumSelectField } from 'src/components/Form/EnumSelectField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ListView } from 'src/components/ListView/ListView';
import { TransactionBindingTypeIndicator } from 'src/components/TransactionBindingTypeIndicator/TransactionBindingTypeIndicator';
import {
    EntityModelTransactionListItem,
    TransactionStatus as TransactionStatusEnum,
} from 'src/generated-api-client';
import { TransactionDetailsDialog } from 'src/pages/TransactionsPage/TransactionDetailsDialog';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { StringHelper } from 'src/utils/StringHelper';
import styled from 'styled-components';

export type TransactionsTabProps = {
    loanId: number;
};

const DEFAULT_FILTER_STATUSES: TransactionStatusEnum[] = [
    TransactionStatusEnum.PENDING,
    TransactionStatusEnum.SUCCESS,
];

export const TransactionsTab = observer(({ loanId }: TransactionsTabProps) => {
    useEffect(() => {
        setupTransactionsTabFilter(loanId);

        TransactionsStore.loadList();

        return () => {
            resetTransactionsTabFilter();
            TransactionsStore.listLoader.reset();
        };
    }, [loanId]);

    return (
        <Card title={t('Loan.Transactions.Title', 'Transactions')}>
            <StyledFilterForm
                store={TransactionsStore}
                initialValues={{
                    statuses: DEFAULT_FILTER_STATUSES,
                }}
            >
                <TextField
                    name="transactionId"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.TransactionId',
                    )}
                />
                <NumberField
                    name="amountFrom"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.AmountFrom',
                    )}
                />
                <NumberField
                    name="amountTo"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.AmountTo',
                    )}
                />
                <EnumSelectField
                    mode="multiple"
                    name="statuses"
                    enumName="TRANSACTION_STATUS"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.Statuses',
                    )}
                />
                <EnumSelectField
                    mode="multiple"
                    name="types"
                    enumName="TRANSACTION_TYPE"
                    label={t('Page.LoanDetails.Tab.Transactions.Filter.Types')}
                />
                <EnumSelectField
                    mode="multiple"
                    name="bindingTypes"
                    enumName="TRANSACTION_BINDING_TYPE"
                    label={t('Page.Transactions.Filter.BindingTypes')}
                />
                <DatePickerField
                    name="updatedAtAfter"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.UpdatedAtAfter',
                    )}
                />
                <DatePickerField
                    name="updatedAtBefore"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.UpdatedAtBefore',
                    )}
                />

                <DatePickerField
                    name="repaymentScheduleDateAfter"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.RepaymentScheduleDateAfter',
                    )}
                />
                <DatePickerField
                    name="repaymentScheduleDateBefore"
                    label={t(
                        'Page.LoanDetails.Tab.Transactions.Filter.RepaymentScheduleDateBefore',
                    )}
                />
            </StyledFilterForm>
            <StyledListView
                store={TransactionsStore}
                columns={columns}
                loading={TransactionsStore.listLoader.isLoading}
                onRowClick={(_e, record) => {
                    TransactionDetailsDialog.render({
                        transactionId: record.id,
                    });
                }}
            />
        </Card>
    );
});

const StyledListView = styled(ListView)`
    tr.ant-table-row {
        &.green {
            td {
                background-color: #e9fcdb;
            }

            &:hover {
                td {
                    background-color: #ddffc3 !important;
                }
            }
        }

        &.orange {
            td {
                background-color: #faebc5;
            }

            &:hover {
                td {
                    background-color: #ffe5ad !important;
                }
            }
        }
    }
`;

function setupTransactionsTabFilter(loanId?: number) {
    TransactionsStore.filterCriteria.setDefaultFilter({
        loanId,
        statuses: DEFAULT_FILTER_STATUSES,
    });
    TransactionsStore.filterCriteria.setExcludedFilterKeysFromCalculatedHasValue(
        ['loanId'],
    );
    TransactionsStore.filterCriteria.resetFilter();
}

function resetTransactionsTabFilter() {
    TransactionsStore.filterCriteria.setDefaultFilter({});
    TransactionsStore.filterCriteria.setExcludedFilterKeysFromCalculatedHasValue(
        ['search'],
    );
    TransactionsStore.filterCriteria.resetFilter();
}

const columns: ColumnsType<EntityModelTransactionListItem> = [
    {
        key: 'transactionId',
        dataIndex: 'transactionId',
        title: <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.Id" />,
        render: (value, record) => {
            return (
                <Space size={10}>
                    <TransactionBindingTypeIndicator transaction={record} />
                    <DataView
                        hideLabel
                        value={value}
                        format={(value) =>
                            StringHelper.sliceFirstWithEllipsis(value, 6)
                        }
                        tooltip={value}
                    />
                </Space>
            );
        },
        width: 120,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: (
            <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.Status" />
        ),
        render: (value) => {
            return (
                <DataView
                    value={value}
                    format={() => <TransactionStatus status={value} />}
                    hideLabel
                />
            );
        },
        sorter: true,
    },
    {
        key: 'date', // TODO: check createdAt and updatedAt in documentation
        title: <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.Date" />,
        render: (item: EntityModelTransactionListItem) => {
            return <DataView value={item.updatedAt} format="date" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.Type" />,
        sorter: true,
        render: (value) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => (
                        <EnumView enumName="TRANSACTION_TYPE" value={value} />
                    )}
                />
            );
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: (
            <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.Amount" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="money" />;
        },
        sorter: true,
    },
    {
        key: 'repaymentScheduleDate',
        dataIndex: 'repaymentScheduleDate',
        title: (
            <Trans i18nKey="Page.LoanDetails.Tab.Transactions.Items.ScheduleConnection" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="date" />;
        },
        sorter: true,
    },
];

const StyledFilterForm = styled(FilterForm)`
    margin-bottom: 20px;
`;

import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';
import { KeycloakBehaviorStrategy } from 'src/stores/AuthStore/KeycloakBehaviorStrategy';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { TokenHelper } from 'src/utils/TokenHelper';

import { KeycloakBehaviorManager } from './KeycloakBehaviorManager';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;

export class AuthStoreKeycloakClass implements IAuthStore {
    @observable keycloak?: Keycloak;

    @observable authenticated = false;
    @observable token?: string;
    @observable keycloakBehaviorStrategy!: KeycloakBehaviorStrategy;

    @action setupKeycloak() {
        if (!SettingsStore.settings?.authentication) {
            console.error('Keycloack settings is not provided');

            return;
        }
        this.keycloak = new Keycloak(
            SettingsStore.settings?.authentication as KeycloakConfig,
        );
        this.keycloak.onTokenExpired = () => {
            this.refreshTokenIfNeed();
        };
    }

    @action solveKeycloakBehaviorStrategy() {
        const manager = new KeycloakBehaviorManager(this.keycloak!);
        this.keycloakBehaviorStrategy = manager.getKeycloakBehaviorStrategy();
    }

    @action async init() {
        await this.setupKeycloak();
        await this.solveKeycloakBehaviorStrategy();
    }

    @action async refreshTokenIfNeed() {
        await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
        this.token = this.keycloak?.token;
    }

    @action async authenticate() {
        try {
            const authenticated =
                await this.keycloakBehaviorStrategy?.authenticate();

            if (!authenticated) {
                await this.keycloak?.login({ kcLocale: 'en' } as any);
            }

            this.authenticated = authenticated;
            this.token = this.keycloak?.token;
        } catch (error) {
            this.authenticated = false;
            this.token = undefined;
        }
    }

    @action async logout(_redirectUrl?: string) {
        await this.keycloakBehaviorStrategy.logout();
    }

    @action async changePassword() {
        await this.keycloak?.login({ action: 'UPDATE_PASSWORD' });
    }

    @computed get permissions() {
        if (!this.token) {
            return [];
        }

        const parsedToken = TokenHelper.decode(this.token) as {
            authorities?: string[];
        };

        return parsedToken.authorities || [];
    }

    resetPassword(): Promise<void> {
        throw new Error('Method not implemented.');
    }
}

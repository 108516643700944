import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useMemo } from 'react';
import { Empty } from 'src/components/Empty/Empty';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { getPropertyPathAsProp } from 'src/utils/ObjectHelper';
import { getPagination } from 'src/utils/PaginationUtils';
import styled from 'styled-components';

export type ListViewProps<T extends Entity> = TableProps<T> & {
    store?: BasicStore<T>;
    dataSource?: Array<T>;
    columns?: ColumnsType<T>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRowClick?: (e: MouseEvent, record: T) => void;
};

export const ListView = observer(
    ({
        store,
        columns,
        onRowClick,
        dataSource,
        ...props
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
    ListViewProps<any>) => {
        const cols = useMemo(() => {
            return !store ? columns : applyDefaultSorting(columns, store);
        }, []);
        const pagination = store ? getPagination(store) : false;
        const items = store ? store.list : dataSource;
        const rowKey = props.rowKey || 'id';

        return (
            <StyledTable
                {...props}
                columns={cols}
                pagination={pagination}
                dataSource={items}
                rowKey={rowKey}
                locale={{
                    emptyText: (
                        <Empty notFound={store?.filterCriteria?.hasFilter} />
                    ),
                }}
                onRow={(record) => {
                    if (onRowClick) {
                        return {
                            onClick: (e) => {
                                !e.defaultPrevented && onRowClick(e, record);
                            },
                        };
                    }

                    return {};
                }}
                // eslint-disable-next-line max-params
                onChange={(_pagination, _filters, sorter, actionInfo) => {
                    if (!store) {
                        return;
                    }
                    if (actionInfo.action === 'sort') {
                        const sortFields = getSortFields(sorter)
                            .filter(isColumnSorter)
                            .filter(Boolean);

                        if (sortFields.length > 0) {
                            const fields = sortFields.map((field) => ({
                                field: field.column?.dataIndex as any,
                                order: field.order,
                            }));
                            store.setSortFields(fields);
                        } else {
                            store.setDefaultSorting();
                        }

                        // if (isColumnSorter(sorter)) {
                        //     store.setSorting(
                        //         sorter.column?.dataIndex as any,
                        //         sorter.order as any,
                        //     );
                        // } else {
                        //     store.setDefaultSorting();
                        // }
                    }
                }}
                showSorterTooltip={false}
                scroll={{ x: true }}
            />
        );
    },
);

function getSortFields(sorter: SorterResult<any> | SorterResult<any>[]) {
    if (Array.isArray(sorter)) {
        return sorter;
    }

    return [sorter];
}

function isSorter(value: any): value is SorterResult<any> {
    return !Array.isArray(value);
}

function isColumnSorter(
    value: SorterResult<any>,
): value is Required<SorterResult<any>> {
    return (
        !!value && !!value.column && !!value.order && !!value.column.dataIndex
    );
}

const applyDefaultSorting = (
    columns: ColumnsType<any> | undefined,
    store: BasicStore<any>,
) => {
    if (!store.sorter) {
        return columns;
    }

    return columns?.map((col) => {
        const colDataIndex = getPropertyPathAsProp((col as any).dataIndex);
        const defaultDataIndex = getPropertyPathAsProp(
            store.sorter?.field as any,
        );

        if (colDataIndex === defaultDataIndex) {
            return {
                ...col,
                defaultSortOrder: store.sorter?.order as any,
            } as ColumnType<any>;
        }

        return {
            ...col,
        };
    });
};

export const StyledTable = styled(Table)`
    ${(props) => {
        const onRowImplementation = props.onRow && props.onRow({});
        if (onRowImplementation?.onClick) {
            return `tbody tr:hover { cursor: pointer; } `;
        }
    }}
`;

import { useTranslation } from 'react-i18next';

export type TrueFalseProps = {
    // eslint-disable-next-line react/require-default-props
    value?: boolean;
};

export const TrueFalseView = ({ value }: TrueFalseProps) => {
    const { t } = useTranslation();
    if (value === true) {
        return <span>{t('Component.TrueFalse.True')}</span>;
    }

    if (value === false) {
        return <span>{t('Component.TrueFalse.False')}</span>;
    }

    return null;
};

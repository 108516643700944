import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { LoanStatus as LoanStatusEnum } from 'src/generated-api-client';

type StatusProps = {
    status?: LoanStatusEnum;
};

export const LoanStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: LoanStatusEnum) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="LOAN_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [LoanStatusEnum.ACTIVE]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatusEnum.ACTIVE} />
        ),
        color: '#151813',
        backgroundColor: '#d3f3bb',
    },
    [LoanStatusEnum.OVERDUE]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatusEnum.OVERDUE} />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [LoanStatusEnum.PENDING]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatusEnum.PENDING} />
        ),
        color: '#797064',
        backgroundColor: '#FBEED5',
    },
    [LoanStatusEnum.CLOSED]: {
        title: (
            <EnumView enumName="LOAN_STATUS" value={LoanStatusEnum.CLOSED} />
        ),
        color: '#3D4637',
        backgroundColor: '#E6E9EB',
    },
};

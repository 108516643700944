import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import styled from 'styled-components';

const menu = () => {
    return (
        <Menu>
            {/* <Menu.Item
                onClick={() => {
                    Router.navigate(RoutePaths.profile);
                }}
            >
                <Trans i18nKey="App.Dropdown.Menu.Profile" />
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    AuthStore.changePassword();
                }}
            >
                <Trans i18nKey="App.Dropdown.Menu.ChangePassword" />
            </Menu.Item> */}
            <Menu.Item
                onClick={() => {
                    AuthStore.logout();
                }}
            >
                <Trans i18nKey="App.Dropdown.Menu.Logout" />
            </Menu.Item>
        </Menu>
    );
};

export const UserMenu = observer(() => {
    return (
        <Dropdown overlay={menu} placement="bottomRight">
            <StyledUserName>
                <UserName>{AppUser.userProfile?.name}</UserName>
                <StyledAvatar>
                    <div>{AppUser.userProfile?.name?.[0]}</div>
                </StyledAvatar>
            </StyledUserName>
        </Dropdown>
    );
});

const StyledUserName = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const UserName = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: right;

    color: #2e3e5c;
    margin-right: 8px;
`;

const StyledAvatar = styled.div`
    position: relative;
    display: inline-flex;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2f333a;
    text-transform: uppercase;
    font-weight: 700;
    border: 2px solid #dfe0eb;
    color: #ffffff;
`;

import {
    Button,
    Form as AntForm,
    FormInstance,
    FormProps,
    Modal as AntModal,
    ModalProps as AntModalProps,
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'src/components/Form/Form';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export type ModalProps = AntModalProps & {
    // eslint-disable-next-line react/require-default-props
    formProps?: Omit<FormProps, 'onFinish' | 'initialValues'>;
    // eslint-disable-next-line react/require-default-props
    form?: FormInstance;
    // eslint-disable-next-line react/require-default-props
    onFinish?: FormProps['onFinish'];
    // eslint-disable-next-line react/require-default-props
    isLoading?: boolean;
    // eslint-disable-next-line react/require-default-props
    showOnlyLoading?: boolean;
    // eslint-disable-next-line react/require-default-props
    initialValues?: FormProps['initialValues'];
};

export const Modal: React.FC<ModalProps> = ({
    formProps,
    form,
    onFinish,
    initialValues,
    isLoading,
    showOnlyLoading,
    children,
    onCancel,
    ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ModalProps) => {
    const { t } = useTranslation();

    const [innerForm] = AntForm.useForm();

    const targetForm = useMemo(() => {
        return form || innerForm;
    }, [form]);

    const okText = props.okText || t('Component.Modal.Btn.Ok');
    const cancelText = props.cancelText || t('Component.Modal.Btn.Cancel');

    const handleOnCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isLoading) {
            return;
        }

        onCancel?.(e);
    };

    return (
        <StyledAntModal
            onCancel={handleOnCancel}
            footer={[
                <Button
                    {...props.cancelButtonProps}
                    key="cancel"
                    disabled={isLoading || props.cancelButtonProps?.disabled}
                    onClick={handleOnCancel}
                >
                    {cancelText}
                </Button>,
                <Button
                    {...props.okButtonProps}
                    key="ok"
                    type="primary"
                    disabled={isLoading || props.okButtonProps?.disabled}
                    onClick={async (e) => {
                        await targetForm.submit();
                        await props.onOk?.(e);
                    }}
                >
                    {okText}
                </Button>,
            ]}
            {...props}
        >
            {showOnlyLoading && isLoading ? (
                <StyledWrapper>
                    <Spinner />
                </StyledWrapper>
            ) : (
                <StyledForm
                    form={targetForm}
                    onFinish={async (values) => {
                        try {
                            await onFinish?.(values);
                            await onCancel?.(true as any);
                        } catch (error) {
                            console.warn(error);
                        }
                    }}
                    initialValues={initialValues}
                    {...formProps}
                >
                    {isLoading && <StyledSpinner />}
                    {children}
                </StyledForm>
            )}
        </StyledAntModal>
    );
};

const StyledForm = styled(Form)`
    position: relative;
`;

const StyledWrapper = styled.div`
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
    opacity: 0.7;
`;

const StyledAntModal = styled(AntModal)`
    .ant-modal-content {
        border: 1px solid #d7d7d7;
        border-radius: 8px;
        overflow: hidden;
        padding: 40px;
    }

    .ant-modal-header {
        border: none;
        padding: 0 0 30px 0;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.5px;
        color: #2e3e5c;
    }

    .ant-modal-close:hover .ant-modal-close-x svg {
        fill: #51688b;
    }

    .ant-modal-footer {
        display: flex;
        padding: 20px 0 0;
        justify-content: end;
    }
`;

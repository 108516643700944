/* eslint-disable max-lines */
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { Gender, LoanMemberInfo } from 'src/generated-api-client';
import { CompanyInfoTab } from 'src/pages/LoanPage/CompanyInfoTab';
import { LoanPaymentInformation } from 'src/pages/LoanPage/LoanPaymentInformation';
import { LoanStatus } from 'src/pages/LoanPage/LoanStatus';
import { LogsTab } from 'src/pages/LoanPage/LogsTab';
import { NotificationsTab } from 'src/pages/LoanPage/NotificationsTab';
import { RepaymentScheduleTab } from 'src/pages/LoanPage/RepaymentScheduleTab';
import { TransactionsTab } from 'src/pages/LoanPage/TransactionsTab';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DateHelper } from 'src/utils/DateHelper';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const CONTRACT_NAME = 'contract.pdf';

const getName = (memberInfo?: LoanMemberInfo) => {
    return [
        memberInfo?.gender === Gender.MALE
            ? t('LoanDetails.Gender.Male', 'Mr.')
            : t('Page.LoanDetails.Gender.Female', 'Ms.'),
        memberInfo?.firstName,
        memberInfo?.patronymic?.[0]
            ? `${memberInfo?.patronymic?.[0]}.`
            : undefined,
        memberInfo?.lastName,
    ]
        .filter(Boolean)
        .join(' ');
};

export const LoanPage = observer(() => {
    const { loanId } = useParams<{ loanId: string }>();
    const loan = LoansStore.currentItem;

    useEffect(() => {
        if (loanId) {
            LoansStore.loadItem(Number(loanId));
        }
    }, [loanId]);

    const tabs: Array<TabDefinition> = [
        {
            tabKey: 'companyInfo',
            title: t('Page.LoanDetails.Tabs.CompanyInfo', 'Company info'),
            Component: <CompanyInfoTab loan={loan} />,
        },
        {
            tabKey: 'repayment-schedule',
            title: t(
                'Page.LoanDetails.Tabs.RepaymentSchedule',
                'Repayments Schedule',
            ),
            Component: <RepaymentScheduleTab loanId={Number(loanId)} />,
        },
        {
            tabKey: 'notifications',
            title: t('Page.LoanDetails.Tabs.Notifications', 'Notifications'),
            Component: <NotificationsTab loanId={Number(loanId)} />,
        },
        {
            tabKey: 'transactions',
            title: t('Page.LoanDetails.Tabs.Transactions', 'Transactions'),
            Component: <TransactionsTab loanId={Number(loanId)} />,
        },
        {
            tabKey: 'logs',
            title: t('Page.LoanDetails.Tabs.Logs', 'Logs'),
            Component: <LogsTab loanId={Number(loanId)} />,
        },
    ];

    const linkToContract = loan?.contractUrl
        ? `${loan.contractUrl}?access_token=${AuthStore.token}`
        : undefined;

    if (!loanId) {
        return null;
    }

    return (
        <BasePage
            isLoading={LoansStore.itemLoader.isLoading}
            showOnlyLoading={!loan}
        >
            <StyledPageBrief>
                <StyledPageBriefItemWrapper>
                    <Tooltip title={loan?.companyInfo.name}>
                        <StyledTitle level={2}>
                            {loan?.companyInfo.name}
                        </StyledTitle>
                    </Tooltip>
                    <StyledCompanyIdentity
                        value={loan?.companyInfo.identity}
                        format="string"
                        label={t('Loan.CompanyInfo.Identity', 'YTR/СRN')}
                        copy
                    />
                </StyledPageBriefItemWrapper>
                <StyledPageBriefItemWrapper>
                    <Tooltip title={getName(loan?.memberInfo)}>
                        <StyledTitle level={4}>
                            {getName(loan?.memberInfo)}
                        </StyledTitle>
                    </Tooltip>
                    <StyledMemberDataView
                        value={loan?.memberInfo.email}
                        format="mail"
                        hideLabel
                    />
                    <StyledMemberDataView
                        value={loan?.memberInfo.phone}
                        format="tel"
                        hideLabel
                    />
                </StyledPageBriefItemWrapper>
                <StyledPageBriefItemWrapper>
                    <StyledApplicationNumber
                        value={loan?.applicationNumber}
                        format={(id) => {
                            return (
                                <Typography.Title level={2}>
                                    {id}
                                </Typography.Title>
                            );
                        }}
                        hideLabel
                        copy={{
                            getValue: () => {
                                return String(loan?.applicationNumber);
                            },
                        }}
                    />
                </StyledPageBriefItemWrapper>
                <StyledPageBriefItemWrapper>
                    <LoanStatus status={loan?.status} />
                </StyledPageBriefItemWrapper>
            </StyledPageBrief>

            <LoanPaymentInformation loan={loan} />

            <StyledLoanInformation
                title={t('Page.LoanDetails.LoanInfo', 'Loan info')}
                extra={
                    linkToContract && (
                        <StyledDownloadBtn
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                UrlHelper.downloadFile(
                                    linkToContract,
                                    CONTRACT_NAME,
                                );
                            }}
                            type="link"
                        >
                            {t(
                                'Page.LoanDetails.LoanInfo.Contract.DownloadContract',
                                'Download Contract',
                            )}
                        </StyledDownloadBtn>
                    )
                }
            >
                <Row gutter={24}>
                    <Col xs={6}>
                        <DataView
                            value={loan?.nextPayment?.amount}
                            label={
                                <>
                                    {t(
                                        'Page.LoanDetails.NextPayment',
                                        'Next payment by',
                                    )}{' '}
                                    {loan?.nextPayment?.date &&
                                        DateHelper.formatDate(
                                            loan?.nextPayment?.date,
                                        )}
                                </>
                            }
                            format="money"
                        />
                    </Col>
                    <Col xs={4}>
                        <DataView
                            value={loan?.term}
                            label={t('Page.LoanDetails.Term', 'Loan term')}
                            format={(terms) =>
                                t('Units.Months', { count: terms })
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <DataView
                            value={loan?.loanAmount}
                            label={t('Page.LoanDetails.Amount', 'Loan amount')}
                            format="money"
                        />
                    </Col>
                    <Col xs={4}>
                        <DataView
                            value={loan?.bid}
                            label={t(
                                'Page.LoanDetails.InterestRate',
                                'Interest rate',
                            )}
                            format="percent"
                        />
                    </Col>
                    <Col xs={4}>
                        <DataView
                            value={loan?.createdAt}
                            label={t(
                                'Page.LoanDetails.CreatedAt',
                                'Created at',
                            )}
                            format={(value) => {
                                return DateHelper.format(value, 'DD.MM.YY');
                            }}
                        />
                    </Col>
                </Row>
            </StyledLoanInformation>
            <Tabs tabs={tabs} />
        </BasePage>
    );
});

const StyledRow = styled(Row)`
    flex-wrap: nowrap;
    margin-bottom: 24px;
`;

const StyledPageBriefItemWrapper = styled.div`
    position: relative;
    max-width: 100%;
    overflow: hidden;
`;

const StyledLoanInformation = styled(Card)`
    background: #f5f6f7;
    border-radius: 8px;
    padding: 24px;

    .app-data-view__value {
        margin-top: 8px;
        font-weight: 100;

        * {
            font-size: 28px;
        }
    }
`;

const StyledTitle = styled(Typography.Title)`
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px !important;
`;

const StyledPageBrief = styled.div`
    display: grid;
    grid-template-columns: 1fr fit-content(300px) fit-content(200px) fit-content(
            100px
        );
    column-gap: 24px;
    margin-bottom: 24px;
`;

const StyledCompanyIdentity = styled(DataView)`
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    .app-data-view__label {
        white-space: nowrap;
        width: fit-content;
    }
`;

const StyledMemberDataView = styled(DataView)`
    margin-bottom: 5px;
`;

const StyledApplicationNumber = styled(DataView)`
    .ant-typography {
        margin-bottom: 0 !important;
    }

    svg {
        font-size: 22px;
    }
`;

const StyledDownloadBtn = styled(Button)`
    padding-left: 0;
    padding-right: 0;
`;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Router } from 'src/core/router/Router';
import { UrlHelper } from 'src/utils/UrlHelper';

const setSearchParams = (params: Record<string, any>) => {
    Router.setSearchParams(params);
};

const applySearchParams = (params: Record<string, any>) => {
    Router.applySearchParams(params);
};

export function useSearchParams() {
    const location = useLocation();
    const searchParams = useMemo(() => {
        return UrlHelper.parseSearchParams(location.search);
    }, [location.search]);

    return {
        params: searchParams,
        setSearchParams,
        applySearchParams,
    };
}

import { EntityModelEventListItem } from 'src/generated-api-client';
import { eventsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export type EventsFilterCriteria = {
    search?: string;
};

export class EventsStoreClass extends BasicStore<EntityModelEventListItem> {
    api: BasicStoreApi<
        EntityModelEventListItem,
        EntityModelEventListItem,
        EntityModelEventListItem,
        EntityModelEventListItem
    > = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                eventsApi.getEvents(
                    this.pager.page,
                    this.pager.size,
                    this.sorter.getSort(),
                    this.filterCriteria.filter.search,
                    this.loanId,
                ),
            );

            this.pager.setTotal(response.page?.totalElements || 0);

            // eslint-disable-next-line no-underscore-dangle
            return response._embedded?.eventListItems || [];
        },
    };

    sorter = new Sorter();
    pager = new Pager();
    filterCriteria = new FilterCriteria<EventsFilterCriteria>();

    constructor(private loanId?: number) {
        super();
    }
}

export const LoanEventsStore = new EntityProvider(EventsStoreClass);
export const EventsStore = new EventsStoreClass();

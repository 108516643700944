import { CopyOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
    DataFormatterFormats,
    DataFormatterProvider,
} from 'src/utils/DataFormatterProvider';
import { DataHelper } from 'src/utils/DataHelper';
import styled, { StyledComponent } from 'styled-components';

export type DataViewCopyOptions = { getValue: () => string };

export type DataViewProps = {
    value: React.ReactNode | boolean;
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    hideLabel?: boolean;
    // eslint-disable-next-line react/require-default-props
    format?:
        | DataFormatterFormats
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        | ((value: any, options: any) => React.ReactNode);
    // eslint-disable-next-line react/require-default-props
    formatterProps?: any;
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    align?: 'vertical' | 'horizontal';

    // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
    overflow?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
    tooltip?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props, @typescript-eslint/no-unused-vars
    copy?: boolean | DataViewCopyOptions;
};

const LONG_DASH = '—';

export const DataView = ({
    label,
    hideLabel,
    value,
    format,
    formatterProps,
    className,
    align = 'vertical',
    overflow = false,
    tooltip,
    copy,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
DataViewProps) => {
    const formatter = useMemo(() => {
        if (typeof format === 'function') {
            return (value: any, options: any) => format(value, options);
        }
        const targetFormat = format || DataFormatterProvider.guessFormat(value);

        return DataFormatterProvider.getFormatter(targetFormat);
    }, [format, value]);

    const hasValue =
        (value !== null && value !== undefined && value !== '') ||
        (typeof value === 'number' && Number.isFinite(Number(value)));

    const classes = {
        'app-data-view': true,
        'align-horizontal': align === 'horizontal',
    };

    const getValue = () => {
        if (hasValue) {
            const formattedValue = formatter(value, formatterProps);
            const formattedValueIsNotObject =
                typeof formattedValue !== 'object';
            const copyGetValueIsFunction =
                typeof (copy as DataViewCopyOptions)?.getValue === 'function';

            return (
                <Space size={5}>
                    {formattedValue}
                    {copy && (
                        <StyledCopyButton
                            type="text"
                            icon={<CopyOutlined />}
                            onClick={() => {
                                const getValueForCopy = copyGetValueIsFunction
                                    ? (copy as DataViewCopyOptions)?.getValue
                                    : () => {
                                          return String(
                                              formattedValueIsNotObject
                                                  ? formattedValue
                                                  : value,
                                          );
                                      };

                                DataHelper.copyToClipboard(getValueForCopy());
                            }}
                        />
                    )}
                </Space>
            );
        }

        return <span>{LONG_DASH}</span>;
    };

    return (
        <StyledDataViewWrapper className={classNames(className, classes)}>
            {!hideLabel && <StyledLabel>{label || <>&nbsp;</>}</StyledLabel>}
            {tooltip ? (
                <Tooltip title={tooltip}>
                    <StyledValue overflow={overflow}>{getValue()}</StyledValue>
                </Tooltip>
            ) : (
                <StyledValue overflow={overflow}>{getValue()}</StyledValue>
            )}
        </StyledDataViewWrapper>
    );
};

const StyledDataViewWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;

    label {
        word-break: break-word;
    }

    a {
        cursor: pointer;
    }

    &.align-horizontal {
        display: flex;

        label {
            margin: 0 10px 0 0;
            line-height: 150%;
        }

        div {
            width: fit-content;
        }
    }
`;

const StyledLabel = styled.label.attrs((props) => ({
    className: classNames(props.className, 'app-data-view__label'),
}))`
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9fa2b4;
`;

type DataViewValueProps = {
    overflow?: boolean;
};

const StyledValue: StyledComponent<
    'div',
    any,
    DataViewValueProps,
    never
> = styled.div.attrs<DataViewValueProps>(
    ({ className, overflow, ...props }) => ({
        className: classNames(className, 'app-data-view__value', {
            'app-data-view__value_overflow-ellipsis': overflow,
        }),
        ...props,
    }),
)`
    //font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
    word-break: break-all;
    width: fit-content;
    max-width: 100%;

    &.app-data-view__value_overflow-ellipsis {
        overflow: hidden;
        white-space: no-wrap;
        text-overflow: ellipsis;
    }
`;

const StyledCopyButton = styled(Button)`
    height: auto;
    padding: 0;
    width: fit-content;
`;

import { useTranslation } from 'react-i18next';

export type YesNoProps = {
    // eslint-disable-next-line react/require-default-props
    value?: boolean;
};

export const YesNoView = ({ value }: YesNoProps) => {
    const { t } = useTranslation();
    if (value === true) {
        return <span className="value-yes">{t('Component.YesNo.Yes')}</span>;
    }

    if (value === false) {
        return <span className="value-no">{t('Component.YesNo.No')}</span>;
    }

    return null;
};

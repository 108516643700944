/* eslint-disable max-lines */
import { Rule, RuleObject } from 'antd/lib/form';
import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Moment } from 'moment';
import { Trans } from 'react-i18next';

export enum AttachmentsFormat {
    jpeg = 'image/jpeg',
    gif = 'image/gif',
    png = 'image/png',
    bmp = 'image/bmp',
    tiff = 'image/tiff',
    image = 'image/*',
    pdf = 'application/pdf',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    csv = 'text/csv',
    html = 'text/html',
}

export enum FileSizeUnit {
    bytes = 1,
    Kb = 2 ** 10,
    Mb = 2 ** 20,
    Gb = 2 ** 30,
    Tb = 2 ** 40,
}

export enum FieldMaxLength {
    Default = 100,
    Name = 100,
    Address = 100,
    Email = 100,
    Comment = 500,
    PeriodDays = 30,
    PeriodHours = 23,
    PeriodMinutes = 59,
    AmlCheckDurationHours = 1000,
    RepeatedRequestsAmount = 1000,
    LoanProductName = 55,
    LoanProductCode = 10,
}

const NumberPattern = new RegExp(/^\+?\d*$/);
const RegExpName = new RegExp(/^[\p{L}, \s-]+$/u);
const withoutStartEndSpacesPattern = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);

const getIdNumberPattern = (lastTwoYearDigits: string, count = '6') => {
    return new RegExp(`^\\d{4}${lastTwoYearDigits}\\d{${count}}$`);
};

const name: Rule = {
    message: <Trans i18nKey="Form.Rules.Validator.Messages.NameError" />,
    pattern: RegExpName,
};

const minLength: Rule = {
    min: 3,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MinLength" />,
};

const minAccountBankLength: Rule = {
    min: 12,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMinLength" />
    ),
};

const maxLength: Rule = {
    max: 100,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MaxLength" />,
};

const maxAccountBankLength: Rule = {
    max: 18,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMaxLength" />
    ),
};

const minPhoneLength: Rule = {
    min: 10,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MinPhoneLengthError" />
    ),
};

const maxPhoneLength: Rule = {
    max: 15,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxPhoneLengthError" />
    ),
};

const maxCommentLength: Rule = {
    max: FieldMaxLength.Comment,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxCommentLengthError" />
    ),
};

const email: Rule = {
    type: 'email',
    message: <Trans i18nKey="Form.Rules.Validator.Messages.EmailError" />,
};

const required: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.Required" />,
};

const requiredPaymentItem: Rule = {
    required: true,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.RequiredPaymentItem" />
    ),
};

const requiredEmail: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.RequiredEmail" />,
};

const requiredPassword: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.RequiredPassword" />,
};

const withoutStartEndSpaces: Rule = {
    pattern: withoutStartEndSpacesPattern,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.WithoutStartEndSpaces" />
    ),
};

const phone: Rule = {
    validator: (_, value) => {
        if (
            !value?.length ||
            (isValidPhoneNumber(value) && NumberPattern.test(value))
        ) {
            return Promise.resolve(true);
        }

        return Promise.reject(new Error(t('Form.Rules.WrongFormat')));
    },
};

const number: Rule = {
    pattern: new RegExp(/^\d{1,}$/),
    message: <Trans i18nKey="Form.Rules.WrongFormat" />,
};

const idNumber: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.Validator.Messages.NationalId'));
                }
            });
        },
    };
};

const onlyNumber: Rule = {
    pattern: new RegExp(/^\d+$/),
    message: <Trans i18nKey="Form.Rules.Validator.Messages.OnlyNumberError" />,
};

const nationalId: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.WrongFormat'));
                }
            });
        },
    };
};

const getFileSizeInBytes = (size: number, unit: FileSizeUnit) => {
    return size * unit;
};

const maxFileSize = (size: number, unit: FileSizeUnit = FileSizeUnit.Mb) => {
    return {
        validator: (_: any, value: any) => {
            if (value && value?.length !== 0) {
                if (
                    value[0].originFileObj.size < getFileSizeInBytes(size, unit)
                ) {
                    return Promise.resolve(true);
                }

                return Promise.reject(
                    new Error(
                        t('Form.Rules.Validator.Messages.MaxFileSize', {
                            context: FileSizeUnit[unit],
                            size,
                        }),
                    ),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const fileFormats = (formats: AttachmentsFormat[]) => {
    return {
        validator: (_: any, value: any) => {
            if (Array.isArray(value) && value?.length !== 0) {
                if (formats.includes(value[0].originFileObj.type)) {
                    return Promise.resolve(true);
                }

                return Promise.reject(
                    new Error(
                        t('Form.Rules.Validator.Messages.FileFormatsError'),
                    ),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const maxFieldLength = (maxLength: FieldMaxLength = FieldMaxLength.Default) => {
    return {
        validator: (_: any, value: string) => {
            return new Promise((resolve, reject) => {
                if (!value?.length || value?.length <= maxLength) {
                    resolve(true);
                } else {
                    reject(
                        t('Form.Rules.Validator.Messages.maxFieldLength', {
                            maxLength,
                        }),
                    );
                }
            });
        },
    };
};

const numberRange = (from = 0, to = 100) => {
    return {
        validator: (_: any, value: number) => {
            return new Promise((resolve, reject) => {
                if (value < from || value > to) {
                    reject(
                        t('Form.Rules.Validator.Messages.NumberRangeError', {
                            from,
                            to,
                        }),
                    );
                } else {
                    resolve(true);
                }
            });
        },
    };
};

const numberMaxValue = (max = 100) => {
    return {
        validator: (_: any, value: number) => {
            return new Promise((resolve, reject) => {
                if (value > max) {
                    reject(
                        t('Form.Rules.Validator.Messages.NumberMaxValueError', {
                            max,
                        }),
                    );
                } else {
                    resolve(true);
                }
            });
        },
    };
};

const numberMinValue = (min = 0) => {
    return {
        validator: (_: any, value: number) => {
            return new Promise((resolve, reject) => {
                if (value < min) {
                    reject(
                        t('Form.Rules.Validator.Messages.NumberMinValueError', {
                            min,
                        }),
                    );
                } else {
                    resolve(true);
                }
            });
        },
    };
};

const greaterThanZero: Rule = {
    validator: (_, value: number) => {
        return new Promise((resolve, reject) => {
            if (value <= 0) {
                reject(t('Form.Rules.Validator.Messages.GreaterThanZeroError'));
            } else {
                resolve(true);
            }
        });
    },
};

const isOldIdNumber = (value: string, lastTwoYearDigits: string) => {
    if (value.length === 9 && NumberPattern.test(value)) {
        return true;
    }

    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const isNewIdNumber = (value: string, lastTwoYearDigits: string) => {
    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const isEqualPasswords = (target: string) => {
    return {
        type: 'string',

        // eslint-disable-next-line require-await
        validator(_, value: string) {
            if (value === target) {
                return Promise.resolve(true);
            }

            return Promise.reject(
                new Error(t('Form.Rules.Validator.Messages.EqualPasswords')),
            );
        },
    } as Rule;
};

const dateIsBefore = (restriction: Moment) => {
    return {
        validator: (_: any, value: Moment) => {
            return new Promise((resolve, reject) => {
                if (value.isBefore(restriction)) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.Validator.Messages.DateIsBefore'));
                }
            });
        },
    };
};

const dateIsAfter = (restriction: Moment) => {
    return {
        validator: (_: any, value: Moment) => {
            return new Promise((resolve, reject) => {
                if (value.isAfter(restriction)) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.Validator.Messages.DateIsAfter'));
                }
            });
        },
    };
};

export const ValidationRules = {
    maxLength,
    minLength,
    required,
    withoutStartEndSpaces,
    email,
    name,
    idNumber,
    nationalId,
    phone,
    number,
    minPhoneLength,
    maxPhoneLength,
    minAccountBankLength,
    maxAccountBankLength,
    onlyNumber,
    maxCommentLength,
    maxFileSize,
    fileFormats,
    isEqualPasswords,
    maxFieldLength,
    numberRange,
    numberMaxValue,
    numberMinValue,
    greaterThanZero,
    requiredEmail,
    requiredPassword,
    requiredPaymentItem,
    dateIsBefore,
    dateIsAfter,
};

import { notification } from 'antd';
import { t } from 'i18next';
import { action, computed, observable } from 'mobx';
import {
    ConstraintViolationProblem,
    EntityModelTransactionListItem,
} from 'src/generated-api-client';
import { transactionsApi } from 'src/services/apiServices';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import {
    TransactionsFilterCriteria,
    TransactionsStore,
} from 'src/stores/TransactionsStore/TransactionsStore';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

const DEFAULT_TRANSACTIONS_FILTER: TransactionsFilterCriteria = {
    showFreeTransactions: true,
};

export class ConnectFreeTransactionsDialogViewModelClass {
    @observable private innerSelectedItems: Array<Entity> = [];

    connectSelectedTransactionsLoader = new AsyncOperationWithStatus(
        (loanId: number, transactionIds: Set<number>) => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.connectFreeTransactions({
                    loanId,
                    transactionIds,
                }),
            );
        },
    );

    @computed get selectedItems() {
        return this.innerSelectedItems || [];
    }

    @computed get selectedItemsMap() {
        return new Map(this.selectedItems.map((x) => [x.id, x]));
    }

    @action selectItem(transaction: Entity) {
        if (!this.selectedItemsMap.has(transaction.id)) {
            this.innerSelectedItems = [...this.selectedItems, transaction];
        }
    }

    @action unselectItem(transaction: Entity) {
        if (this.selectedItemsMap.has(transaction.id)) {
            this.innerSelectedItems = this.selectedItems.filter(
                (x) => x.id !== transaction.id,
            );
        }
    }

    @action selectItems(transactions: Array<Entity>) {
        const transactionsToSelect = transactions.filter(
            (x) => x && !this.selectedItemsMap.has(x.id),
        );
        this.innerSelectedItems = [
            ...this.selectedItems,
            ...transactionsToSelect,
        ];
    }

    @action unselectItems(transactions: Array<Entity>) {
        const transactionIdsToUnselect = transactions
            .filter((x) => x && this.selectedItemsMap.has(x.id))
            .map((x) => x.id);
        this.innerSelectedItems = this.selectedItems.filter(
            (x) => !transactionIdsToUnselect.includes(x.id),
        );
    }

    @computed get countSelectedItems() {
        return this.selectedItems.length;
    }

    @computed get sumAmount() {
        return this.selectedItems.reduce<any>((prev: any, item: any) => {
            return prev + (item as EntityModelTransactionListItem).amount;
        }, 0);
    }

    @action async init() {
        await TransactionsStore.filterCriteria.setDefaultFilter({
            ...DEFAULT_TRANSACTIONS_FILTER,
        });
        await TransactionsStore.filterCriteria.resetFilter();
        TransactionsStore.filterCriteria.setExcludedFilterKeysFromCalculatedHasValue(
            ['search', 'showFreeTransactions'],
        );
        this.innerSelectedItems = [];
        await TransactionsStore.loadList();
    }

    @action async reset() {
        this.innerSelectedItems = [];
        await TransactionsStore.listLoader.reset();
    }

    @computed get selectedItemIds() {
        return this.selectedItems.map((x) => x.id) as Array<any>;
    }

    @action clearSelectedTransactions() {
        this.innerSelectedItems = [];
    }

    @action async loadCurrentLoanItem(loanId: number) {
        LoansStore.itemLoader.turnOnSilentMode();
        await LoansStore.loadItem(loanId);
        LoansStore.itemLoader.turnOffSilentMode();
    }

    @action async connectSelectedTransactions(loanId: number) {
        await this.connectSelectedTransactionsLoader.call(
            loanId,
            this.selectedItemIds as any,
        );

        if (
            this.connectSelectedTransactionsLoader.hasError &&
            isConstraintViolationProblem(
                this.connectSelectedTransactionsLoader.errorData,
            )
        ) {
            notification.error({
                message: t(
                    'Errors.ConnectFreeTransactions.ErrorTitle',
                    'Error has occurred',
                ),
                description:
                    this.connectSelectedTransactionsLoader.errorData.violations
                        ?.map((x) => x.message)
                        .join('\r\n'),
            });
        } else {
            notification.success({
                message: t(
                    'Message.ConnectFreeTransactions.Success',
                    'The initiation of the connection was successful. Data sent to Ecolo',
                ),
                description:
                    this.connectSelectedTransactionsLoader.errorData?.details,
            });
        }

        await this.loadCurrentLoanItem(loanId);
    }
}

export const ConnectFreeTransactionsDialogViewModel =
    new ConnectFreeTransactionsDialogViewModelClass();

const isConstraintViolationProblem = (
    value: any,
): value is ConstraintViolationProblem => {
    return Array.isArray(value.violations);
};

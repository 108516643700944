import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { findDays, findMonths, findYears } from 'pomeranian-durations';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_SERVER_FORMAT = 'HH:mm:ss';
export const ISO_DATE = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD.MM.YYYY hh:mm';
export const DEFAULT_DATE_TIME_SECONDS_FORMAT = 'DD.MM.YYYY hh:mm:ss';

const format = (value: Moment | string, format = DEFAULT_DATE_FORMAT) => {
    const mValue = moment.isMoment(value) ? value : moment(value);

    return mValue.format(format);
};

const formatISODate = (value?: Moment | string) => {
    if (!value) {
        return;
    }

    return format(value, ISO_DATE);
};

const formatISODateTimeSeconds = (value?: Moment | string) => {
    if (!value) {
        return;
    }

    const mValue = moment.isMoment(value) ? value : moment(value);

    return mValue.toISOString();
};

const formatDate = (value: Moment | string) => {
    return format(value);
};

const formatDateTime = (value: Moment | string) => {
    return format(value, DEFAULT_DATE_TIME_FORMAT);
};

const formatDateTimeSeconds = (value: Moment | string) => {
    return format(value, DEFAULT_DATE_TIME_SECONDS_FORMAT);
};

const formatTime = (value: Moment | string) => {
    return format(value, DEFAULT_TIME_FORMAT);
};

const formatTimeSeconds = (value: Moment | string) => {
    return format(value, DEFAULT_TIME_SERVER_FORMAT);
};

const convertPTtoMoment = (value: string) => {
    const years = findYears(value);
    const months = findMonths(value);
    const days = findDays(value);

    return moment({
        years,
        months,
        days,
    });
};

const formatInterval = (value: string) => {
    const years = findYears(value) || 0;
    const months = findMonths(value) || 0;
    const days = findDays(value) || 0;

    return [
        t('Formats.Date.Interval.Years', { count: years }),
        t('Formats.Date.Interval.Months', { count: months }),
        t('Formats.Date.Interval.Days', { count: days }),
    ].join(' ');
};

export const DateHelper = {
    format,
    formatISODate,
    formatISODateTimeSeconds,
    formatDate,
    formatDateTime,
    formatDateTimeSeconds,
    formatTime,
    formatTimeSeconds,
    convertPTtoMoment,
    formatInterval,
};

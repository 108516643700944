import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { SelectField, SelectFieldProps } from 'src/components/Form/SelectField';
import { EnumName, EnumStore } from 'src/stores/EnumStore/EnumStore';

export type EnumSelectFieldProms = SelectFieldProps & {
    enumName: EnumName;
};
export const EnumSelectField = observer(
    ({ enumName, ...props }: EnumSelectFieldProms) => {
        const enumStore = EnumStore.getInstance(enumName);

        useEffect(() => {
            if (!enumStore.listLoader.isLoaded) {
                enumStore.loadList();
            }
        }, [enumName]);

        return (
            <SelectField
                {...props}
                loading={enumStore.listLoader.isLoading}
                options={enumStore.options}
            />
        );
    },
);

import {
    EllipsisOutlined,
    ExclamationCircleFilled,
    SubnodeOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Dropdown,
    Modal as AntModal,
    Row,
    Space,
    Tooltip,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import {
    TransactionDetails,
    TransactionDetailsDialogViewModel,
} from 'src/components/entities/TransactionDetails';
import { Modal } from 'src/components/Modal/Modal';
import { Transaction, TransactionActionType } from 'src/generated-api-client';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { StringHelper } from 'src/utils/StringHelper';
import styled from 'styled-components';

export type TransactionDetailsDialogProps = ModalRendererProps<{
    transactionId: Transaction['id'];
}>;
export const TransactionDetailsDialogFn = observer(
    ({ transactionId, ...props }: TransactionDetailsDialogProps) => {
        useEffect(() => {
            TransactionsStore.loadItem(transactionId);

            return () => {
                TransactionsStore.itemLoader.reset();
            };
        }, [transactionId]);

        const transaction = TransactionsStore.currentItem;
        const isConnected = Boolean(transaction?.applicationNumber);
        const showNotConnected =
            !isConnected && TransactionsStore.itemLoader.isLoaded;

        const transactionActions =
            transaction && getTransactionActions(transaction);

        const title = (
            <Row gutter={10}>
                <Col flex={1}>
                    <Space size="small" align="center">
                        <Tooltip title={transaction?.transactionId}>
                            <StyledTransactionId>
                                {t(
                                    'Dialog.TransactionDetails.Header.TransactionId',
                                    'TR:',
                                )}
                                {StringHelper.sliceFirstWithEllipsis(
                                    transaction?.transactionId,
                                    6,
                                )}
                            </StyledTransactionId>
                        </Tooltip>
                        {isConnected && <StyledIconConnected />}
                        {isConnected && (
                            <StyledLoanId>
                                {t(
                                    'Dialog.TransactionDetails.Header.ApplicationNumber',
                                    'LN:',
                                )}
                                {transaction?.applicationNumber}
                            </StyledLoanId>
                        )}
                        {showNotConnected && (
                            <StyledMessage>
                                <ExclamationCircleFilled />{' '}
                                {t(
                                    'Dialog.TransactionDetails.Header.NotConnected',
                                    'There is no connection',
                                )}
                            </StyledMessage>
                        )}
                    </Space>
                </Col>
                <Col>
                    {transactionActions && transactionActions.length > 0 && (
                        <Dropdown
                            trigger={['click', 'hover']}
                            menu={{
                                items: transactionActions,
                            }}
                        >
                            <Button>
                                <EllipsisOutlined size={20} />
                            </Button>
                        </Dropdown>
                    )}
                </Col>
            </Row>
        );

        return (
            <Modal
                {...props}
                isLoading={TransactionsStore.itemLoader.isLoading}
                footer={false}
                title={title}
                width="70%"
            >
                <TransactionDetails
                    transaction={transaction || ({} as Transaction)}
                />
            </Modal>
        );
    },
);

const getTransactionActions = (transaction: Transaction) => {
    return transaction.actions
        ?.map((action) => {
            if (action.type === TransactionActionType.DISPLAY_TRANSACTION) {
                return {
                    key: action.type,
                    label: t(`Entity.Transaction.Actions.${action.type}`),
                    onClick: () =>
                        new Promise((resolve, reject) => {
                            AntModal.confirm({
                                title: t(
                                    'Dialog.ShowInFreeTransactionList.Confirm.Title',
                                    'Confirm operation',
                                ),
                                content: t(
                                    'Dialog.ShowInFreeTransactionList.Confirm.Content',
                                ),
                                onOk: async () => {
                                    await TransactionDetailsDialogViewModel.showInFreeTransactionList(
                                        transaction.id,
                                    );
                                    resolve({});
                                },
                                onCancel: () => {
                                    reject();
                                },
                            });
                        }),
                } as any;
            }

            if (action.type === TransactionActionType.HIDE_TRANSACTION) {
                return {
                    key: action.type,
                    label: t(`Entity.Transaction.Actions.${action.type}`),
                    onClick: () =>
                        new Promise((resolve, reject) => {
                            AntModal.confirm({
                                title: t(
                                    'Dialog.MarkAsUsedDialog.Confirm.Title',
                                    'Confirm operation',
                                ),
                                content: t(
                                    'Dialog.MarkAsUsedDialog.Confirm.Content',
                                ),
                                onOk: async () => {
                                    await TransactionDetailsDialogViewModel.markAsUsedTransaction(
                                        transaction.id,
                                    );
                                },
                                onCancel: () => {
                                    reject();
                                },
                            });
                        }),
                };
            }

            return undefined;
        })
        .filter(Boolean);
};

export const TransactionDetailsDialog = addModalRenderer(
    TransactionDetailsDialogFn,
);

const StyledTransactionId = styled.div`
    font-size: 24px;
    padding: 2px 6px;
    border-radius: 8px;
    background: rgba(220, 220, 220, 0.6);
`;
const StyledLoanId = styled.div`
    font-size: 24px;
`;

const StyledIconConnected = styled(SubnodeOutlined)`
    transform: translateY(6px);

    svg {
        font-size: 24px;
        fill: #0a65ed;
    }
`;

const StyledMessage = styled.div`
    color: #ff006b;
`;

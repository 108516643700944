import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Modal } from 'src/components/Modal/Modal';
import { EntityModelEventListItem } from 'src/generated-api-client';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type TransactionDetailsDialogProps = ModalRendererProps<{
    event: EntityModelEventListItem;
}>;
export const LogDetailsDialogFn = observer(
    ({ event, ...props }: TransactionDetailsDialogProps) => {
        return (
            <StyledModal
                {...props}
                footer={false}
                title={t('Dialog.Event.Details.Title')}
                width="70%"
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Event.Time')}
                            value={event.createdAt}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Event.Actor')}
                            value={event.actor}
                            format={(value) => {
                                return t(`Entity.Event.Actor.${value}`, event);
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Event.Event')}
                            value={event.type}
                            format={(value) => (
                                <EnumView enumName="EVENT_TYPE" value={value} />
                            )}
                        />
                    </Col>
                    <Col xs={24}>
                        <DataView
                            label={t('Entity.Event.Details')}
                            value={event.details}
                            format={(value) => {
                                return (
                                    <StyledDetails>
                                        {JSON.stringify(value, null, 2)}
                                    </StyledDetails>
                                );
                            }}
                        />
                    </Col>
                </Row>
            </StyledModal>
        );
    },
);

export const LogDetailsDialog = addModalRenderer(LogDetailsDialogFn);

const StyledDetails = styled.code`
    word-wrap: break-word;
    page-break-inside: avoid;
    white-space: break-spaces;
    font-size: 11px;

    max-height: 450px;
    padding: 20px;
    border: 1px solid #e4e4e4;
    display: block;
    overflow: auto;
    margin-top: 5px;
    background: #f5f6fa;
    border-radius: 8px;
`;

const StyledModal = styled(Modal)`
    top: 40px;
`;

/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import {
    CloseCircleOutlined,
    FilterOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    Badge,
    Button,
    Collapse,
    Form as AntForm,
    FormInstance,
    Space,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormProps } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

const { Panel } = Collapse;

export type FilterFormType = Omit<FormProps, 'title'> & {
    children?: React.ReactNode;
    store: BasicStore<any>;
    title?: React.ReactNode;
    panelExtra?: React.ReactNode;
    search?: boolean;
    searchPlaceholder?: string;
    form?: FormInstance<any>;
    onFilter?: () => void;
};

const DEFAULT_PANEL_KEY = '1';

export const FilterForm = observer(
    ({
        children,
        store,
        title,
        panelExtra,
        search,
        searchPlaceholder,
        form,
        onFilter,
        ...props
    }: FilterFormType) => {
        const { t } = useTranslation();
        const [isCollapsed, setIsCollapsed] = useState<number | undefined>();
        const [innerForm] = AntForm.useForm();
        const targetForm = form || innerForm;
        const isSubmittingForm = useRef(false);

        const onChange = (key?: number) => {
            setIsCollapsed(Number(!key));
        };

        const hasFilter = Boolean(store.filterCriteria?.hasFilter);

        const filterBtnText = isCollapsed
            ? t('Component.FilterForm.Button.HideFilters')
            : t('Component.FilterForm.Button.ShowFilters');

        const toggleFilterBtnHandler = () => {
            onChange(isCollapsed);
        };

        const showTitleAboveSearch = Boolean(title) && search;
        const showToggleFilterBtn = Boolean(children);

        return (
            <Form
                form={targetForm}
                onFinish={async (values) => {
                    try {
                        if (!isSubmittingForm.current) {
                            isSubmittingForm.current = true;
                            await store.filterCriteria?.applyFilter(values);
                            await store.pager?.reset();
                            await onFilter?.();
                            await store.loadList();
                        }
                    } catch (error) {
                    } finally {
                        // eslint-disable-next-line require-atomic-updates
                        isSubmittingForm.current = false;
                    }
                }}
                {...props}
            >
                <StyledCollapse activeKey={isCollapsed} accordion ghost>
                    <StyledPanel
                        extra={
                            showToggleFilterBtn && (
                                <Space size="middle">
                                    {panelExtra}
                                    {hasFilter ? (
                                        <Badge dot>
                                            <Button
                                                onClick={toggleFilterBtnHandler}
                                                icon={<FilterOutlined />}
                                            >
                                                {filterBtnText}
                                            </Button>
                                        </Badge>
                                    ) : (
                                        <Button
                                            type={
                                                isCollapsed
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                            onClick={toggleFilterBtnHandler}
                                            icon={<FilterOutlined />}
                                        >
                                            {filterBtnText}
                                        </Button>
                                    )}
                                </Space>
                            )
                        }
                        header={
                            search && (
                                <TextField
                                    name="search"
                                    inputProps={{
                                        allowClear: true,
                                        placeholder:
                                            searchPlaceholder ||
                                            t(
                                                'Component.FilterForm.Search.Placeholder',
                                                'Search...',
                                            ),
                                        extra: (
                                            <Button
                                                icon={<SearchOutlined />}
                                                onClick={() => {
                                                    innerForm.submit();
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            )
                        }
                        showArrow={false}
                        key={DEFAULT_PANEL_KEY}
                    >
                        <FieldsWrapper>{children}</FieldsWrapper>
                        <StyledSpace
                            align="end"
                            direction="horizontal"
                            size={8}
                        >
                            <Button
                                // htmlType="reset"
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                    innerForm.resetFields();
                                    innerForm.submit();
                                }}
                            >
                                {t('Component.FilterForm.Button.ResetFilters')}
                            </Button>
                            <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                onClick={() => {
                                    innerForm.submit();
                                }}
                            >
                                {t('Component.FilterForm.Button.ApplyFilters')}
                            </Button>
                        </StyledSpace>
                    </StyledPanel>
                </StyledCollapse>
            </Form>
        );
    },
);

const StyledSpace = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-header {
        padding: 0 !important;
    }
    .ant-collapse-extra {
        margin-left: 30px;
        display: flex;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }
`;

const FieldsWrapper = styled.div`
    display: grid;
    column-gap: 8px;
    grid-template-columns: repeat(4, 1fr);

    .ant-form-item {
        margin-bottom: 16px;
        overflow: hidden;
    }
`;

const StyledPanel = styled(Panel)`
    .ant-collapse-header {
        cursor: default !important;
    }
    .ant-collapse-header-text {
        padding-right: 8px;
    }
`;

/* eslint-disable max-lines */
import { t } from 'i18next';
import { computed } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { TransactionsPage } from 'src/pages/TransactionsPage/TransactionsPage';
import { RouteDefinition } from 'src/stores/RouterStore/RouterStore.types';

export class RouterStoreClass {
    // eslint-disable-next-line no-underscore-dangle
    @computed private get routeDefinitions(): RouteDefinition[] {
        return [
            {
                path: RoutePaths.index,
                element: IndexPage,
            },
            {
                path: RoutePaths.loans,
                element: LoansPage,
                label: t('App.Menu.Links.Loans'),
            },
            {
                path: RoutePaths.loanDetails,
                element: LoansPage,
            },
            {
                path: RoutePaths.transactions,
                element: TransactionsPage,
                label: t('App.Menu.Links.Transactions'),
            },
        ];
    }

    @computed get routes() {
        return this.routeDefinitions
            .flatMap((route) => {
                if (route.children) {
                    return [route, ...(route.children || [])];
                }

                return route;
            })
            .filter(Boolean) as RouteDefinition[];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routeDefinitions
            .filter((route) => route.label)
            .filter((route) =>
                route.available === undefined ? true : route.available,
            );
    }
}

export const RouterStore = new RouterStoreClass();

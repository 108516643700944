import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { Loan } from 'src/generated-api-client';

export type CompanyInfoTabProps = { loan?: Loan };
export const CompanyInfoTab = observer(({ loan }: CompanyInfoTabProps) => {
    return (
        <>
            <Card
                title={t(
                    'Page.LoanDetails.Tabs.CompanyInfo.Section.CompanyInfo',
                    'Company info',
                )}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={loan?.companyInfo?.name}
                            label={t('Entity.CompanyInfo.Name', 'Name')}
                        />
                        <DataView
                            value={loan?.companyInfo?.identity}
                            label={t('Entity.CompanyInfo.Identity', 'Identity')}
                        />
                    </Col>
                    <Col xs={16}>
                        <DataView
                            value={loan?.companyInfo?.regDate}
                            label={t(
                                'Entity.CompanyInfo.DateOfRegistration',
                                'Date of registration',
                            )}
                            format="date"
                        />
                        <DataView
                            value={loan?.companyInfo?.regForm}
                            label={t(
                                'Entity.CompanyInfo.RegForm',
                                'Legal form',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
            <Card
                title={t(
                    'Page.LoanDetails.Tabs.CompanyInfo.Section.Address',
                    'Address',
                )}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={loan?.companyInfo?.address}
                            label={t(
                                'Entity.CompanyInfo.LegalAddress',
                                'Legal address',
                            )}
                        />
                    </Col>
                </Row>
            </Card>
            <Card
                title={t(
                    'Page.LoanDetails.Tabs.CompanyInfo.Section.BankAccount',
                    'Bank account',
                )}
            >
                <Row gutter={16}>
                    <Col xs={8}>
                        <DataView
                            value={loan?.bank?.accountName}
                            label={t(
                                'Entity.BankAccount.AccountName',
                                'Account name',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={loan?.bank?.sortCode}
                            label={t(
                                'Entity.BankAccount.SortCode',
                                'Sort code',
                            )}
                            format="string"
                        />
                    </Col>
                    <Col xs={8}>
                        <DataView
                            value={loan?.bank?.accountNumber}
                            label={t(
                                'Entity.BankAccount.AccountNumber',
                                'Account number',
                            )}
                            format="string"
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
});

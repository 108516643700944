import { Form, FormItemProps } from 'antd';
import React from 'react';
import { InputNumber } from 'src/components/Input/InputNumber';

export type NumberFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    max?: number;
    // eslint-disable-next-line react/require-default-props
    min?: number;
    // eslint-disable-next-line react/require-default-props
    onlyInteger?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
};

const disableNotNumberKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
        return true;
    }

    if (!Number.isFinite(Number.parseInt(event.key))) {
        event.preventDefault();

        return false;
    }
};

export const NumberField = ({
    name,
    label,
    min,
    max,
    onlyInteger,
    disabled,
    ...props
}: NumberFieldProps) => {
    const targetOnKeyPress = onlyInteger ? disableNotNumberKey : undefined;

    return (
        <Form.Item {...props} name={name} label={label}>
            <InputNumber
                min={min}
                max={max}
                onKeyPress={targetOnKeyPress}
                disabled={disabled}
            />
        </Form.Item>
    );
};

import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnumView } from 'src/components/EnumView/EnumView';
import { StatusInfo, StatusView } from 'src/components/StatusView/StatusView';
import { TransactionStatus as TransactionStatusEnum } from 'src/generated-api-client';

type StatusProps = {
    status?: TransactionStatusEnum;
};

export const TransactionStatus = observer(({ status }: StatusProps) => {
    const statusInfo = getStatusInfo(status);

    return <StatusView statusInfo={statusInfo} />;
});

const getStatusInfo = (status?: TransactionStatusEnum) => {
    return (
        (status && statusInfoMap[status]) || {
            title: <EnumView enumName="TRANSACTION_STATUS" value={status} />,
        }
    );
};

const statusInfoMap: Record<string, StatusInfo> = {
    [TransactionStatusEnum.CANCELLED]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.CANCELLED}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.CONTACT_SUPPORT]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.CONTACT_SUPPORT}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.DECLINED]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.DECLINED}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.ERROR]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.ERROR}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.EXPIRED]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.EXPIRED}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.INSUFFICIENT_FUNDS]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.INSUFFICIENT_FUNDS}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
    [TransactionStatusEnum.PENDING]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.PENDING}
            />
        ),
        color: '#3D4637',
        backgroundColor: '#E6E9EB',
    },
    [TransactionStatusEnum.QUARANTINED]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.QUARANTINED}
            />
        ),
        color: '#3D4637',
        backgroundColor: '#E6E9EB',
    },
    [TransactionStatusEnum.SUCCESS]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.SUCCESS}
            />
        ),
        color: '#151813',
        backgroundColor: '#d3f3bb',
    },
    [TransactionStatusEnum.SYSTEM_ERROR]: {
        title: (
            <EnumView
                enumName="TRANSACTION_STATUS"
                value={TransactionStatusEnum.SYSTEM_ERROR}
            />
        ),
        color: '#FFFFFF',
        backgroundColor: '#FF006B',
    },
};

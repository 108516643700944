import { OptionProps } from 'antd/lib/select';
import i18next from 'i18next';
import { computed } from 'mobx';
import { LocalizedEnum } from 'src/generated-api-client';
import { enumApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ResourceItem = Entity & LocalizedEnum;

export type EnumName = Parameters<typeof enumApi.getEnumValues>[0];

export class EnumStoreClass extends BasicStore<ResourceItem> {
    api: BasicStoreApi<ResourceItem> = {
        loadList: async () => {
            const items = await RequestHelper.unwrapFromAxiosPromise(
                enumApi.getEnumValues(this.enumName as any),
            );

            return items.map((item) => ({ ...item, id: item.key }));
        },
    };

    constructor(private enumName: EnumName) {
        super();
    }

    @computed get options() {
        return this.list.map((item) => ({
            value: item.key,
            children: (item.l10n as any)?.[i18next.language],
        })) as OptionProps[];
    }

    static async loadEnums(enumNames: EnumName[]) {
        await Promise.allSettled(
            enumNames.map((enumName) =>
                EnumStore.getInstance(enumName).loadList(),
            ),
        );
    }
}

export const EnumStore = new EntityProvider(EnumStoreClass);

import { computed } from 'mobx';
import {
    EntityModelLoanListItem,
    Loan,
    LoanStatus,
} from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanFilterCriteria = {
    identity?: string;
    createdAtAfter?: string;
    createdAtBefore?: string;
    status?: Array<LoanStatus>;
    search?: string;
    loanId?: number;
    applicationNumber?: string;
};

export class LoansStoreClass extends BasicStore<
    EntityModelLoanListItem,
    LoanFilterCriteria,
    Loan,
    Loan,
    Loan
> {
    pager = new Pager();
    filterCriteria = new FilterCriteria<LoanFilterCriteria>();

    api: BasicStoreApi<EntityModelLoanListItem, Loan, Loan, Loan> = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.getLoans(
                    this.pager.page,
                    this.pager.size,
                    undefined, // sorter
                    this.filterCriteria.filter.applicationNumber,
                    this.filterCriteria.filter.identity,
                    this.filterCriteria.filter.status,
                    this.filterCriteria.filter.createdAtAfter,
                    this.filterCriteria.filter.createdAtBefore,
                    this.filterCriteria.filter.search,
                    this.filterCriteria.filter.loanId,
                ),
            );

            this.pager.setTotal(response.page?.totalElements || 0);

            // eslint-disable-next-line no-underscore-dangle
            return response._embedded?.loanListItems || [];
        },

        loadItem(id: number) {
            return RequestHelper.unwrapFromAxiosPromise(loansApi.getLoan(id));
        },
    };

    @computed get hasFilterValues() {
        return this.filterCriteria.hasFilter;
    }
}

export const LoansStore = new LoansStoreClass();

import { Checkbox, Form, FormItemProps } from 'antd';
import React from 'react';

export type CheckboxFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    value?: any;
};

export const CheckboxField = ({
    name,
    label,
    disabled,
    value,
    children,
    ...props
}: CheckboxFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label} valuePropName="checked">
            <Checkbox disabled={disabled}>{children}</Checkbox>
        </Form.Item>
    );
};

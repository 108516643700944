import { EntityModelNotificationListItem } from 'src/generated-api-client';
import { loanNotificationsApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter } from 'src/utils/mobx/Sorter';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoanNotificationsStoreClass extends BasicStore<EntityModelNotificationListItem> {
    api: BasicStoreApi<
        EntityModelNotificationListItem,
        EntityModelNotificationListItem,
        EntityModelNotificationListItem,
        EntityModelNotificationListItem
    > = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                loanNotificationsApi.getNotifications(
                    this.pager.page,
                    this.pager.size,
                    this.sorter.getSort(),
                    this.loanId,
                ),
            );

            this.pager.setTotal(response.page?.totalElements || 0);

            // eslint-disable-next-line no-underscore-dangle
            return response._embedded?.notificationListItems || [];
        },
    };

    sorter = new Sorter();
    pager = new Pager();

    constructor(private loanId: number) {
        super();
    }
}

export const LoanNotificationsStore = new EntityProvider(
    LoanNotificationsStoreClass,
);

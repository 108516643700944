/* eslint-disable max-lines */
import { Col, notification, Row } from 'antd';
import { t } from 'i18next';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { TransactionStatus } from 'src/components/entities/TransactionStatus';
import { EnumView } from 'src/components/EnumView/EnumView';
import {
    ConstraintViolationProblem,
    Transaction,
    TransactionType,
} from 'src/generated-api-client';
import { transactionsApi } from 'src/services/apiServices';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TransactionDetailsProps = {
    transaction: Transaction;
};

export const TransactionDetails = observer(
    ({ transaction }: TransactionDetailsProps) => {
        const showBankAccountName =
            transaction.type !== TransactionType.DISBURSEMENT;

        return (
            <>
                <Card
                    title={t(
                        'Components.TransactionDetails.Section.TransactionDetails.Title',
                        'Transaction details',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.TransactionId',
                                    'ID',
                                )}
                                value={transaction.transactionId}
                                format="string"
                            />

                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Status',
                                    'Status',
                                )}
                                value={transaction.status}
                                format={(status) => (
                                    <TransactionStatus status={status} />
                                )}
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.ApplicationNumber',
                                    'Loan №',
                                )}
                                value={transaction.applicationNumber}
                                format="string"
                            />

                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.ConnectedWithRepayment',
                                    'Connected payment from Repayment Schedule',
                                )}
                                value={transaction.repaymentScheduleDate}
                                format="date"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Type',
                                    'Type',
                                )}
                                value={transaction.type}
                                // eslint-disable-next-line max-lines
                                format={(value) => (
                                    <EnumView
                                        enumName="TRANSACTION_TYPE"
                                        value={value}
                                    />
                                )}
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Amount',
                                    'Amount',
                                )}
                                value={transaction.amount}
                                format="money"
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Date',
                                    'Date',
                                )}
                                value={transaction.updatedAt}
                                format="date-time-seconds"
                            />
                        </Col>
                    </Row>
                </Card>
                <Card title={getTransactionBankInformationHeader(transaction)}>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Company',
                                    'Company',
                                )}
                                value={transaction.companyInfo?.name}
                                format="string"
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Identity',
                                    'UTR/CRN',
                                )}
                                value={transaction.companyInfo?.identity}
                                format="string"
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.Comment',
                                    'Comment (Identity, Company, Loan№, etc.)',
                                )}
                                value={transaction.comment}
                                format="string"
                            />
                        </Col>
                        <Col xs={12}>
                            {showBankAccountName && (
                                <DataView
                                    label={t(
                                        'Components.TransactionDetails.Section.TransactionDetails.BankAccountName',
                                        'Bank account name',
                                    )}
                                    value={transaction.bank?.accountName}
                                    format="string"
                                />
                            )}
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.SortCode',
                                    'Sort code',
                                )}
                                value={transaction.bank?.sortCode}
                                format="string"
                            />
                            <DataView
                                label={t(
                                    'Components.TransactionDetails.Section.TransactionDetails.AccountNumber',
                                    'Account number',
                                )}
                                value={transaction.bank?.accountNumber}
                                format="string"
                            />
                        </Col>
                    </Row>
                </Card>
            </>
        );
    },
);

function getTransactionBankInformationHeader(transaction: Transaction) {
    return transaction?.type === TransactionType.DISBURSEMENT
        ? t('Components.TransactionDetails.Section.BankInformation.To', 'To')
        : t(
              'Components.TransactionDetails.Section.BankInformation.From',
              'From',
          );
}

export class TransactionDetailsDialogViewModelClass {
    markAsUsedTransactionsLoader = new AsyncOperationWithStatus(
        (transactionId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.hideFreeTransaction(transactionId),
            );
        },
    );

    showInFreeTransactionListLoader = new AsyncOperationWithStatus(
        (transactionId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.displayFreeTransaction(transactionId),
            );
        },
    );

    @action async markAsUsedTransaction(transactionId: number) {
        await this.markAsUsedTransactionsLoader.call(transactionId);

        if (
            this.markAsUsedTransactionsLoader.hasError &&
            isConstraintViolationProblem(
                this.markAsUsedTransactionsLoader.errorData,
            )
        ) {
            notification.error({
                message: t(
                    'Errors.MarkAsUsedTransactionsLoader.ErrorTitle',
                    'Error has occurred',
                ),
                description:
                    this.markAsUsedTransactionsLoader.errorData.violations
                        ?.map((x) => x.message)
                        .join('\r\n'),
            });
        } else {
            notification.success({
                message: t(
                    'Message.MarkAsUsedTransactionsLoader.Success',
                    'The action successfully completed',
                ),
                description:
                    this.markAsUsedTransactionsLoader.errorData?.details,
            });
        }

        await this.loadCurrentTransactionItem(transactionId);
    }

    @action async loadCurrentTransactionItem(transactionId: number) {
        TransactionsStore.itemLoader.turnOnSilentMode();
        await TransactionsStore.loadItem(transactionId);
        await TransactionsStore.loadList(false);
        TransactionsStore.itemLoader.turnOffSilentMode();
    }

    @action async showInFreeTransactionList(transactionId: number) {
        await this.showInFreeTransactionListLoader.call(transactionId);

        if (
            this.showInFreeTransactionListLoader.hasError &&
            isConstraintViolationProblem(
                this.showInFreeTransactionListLoader.errorData,
            )
        ) {
            notification.error({
                message: t(
                    'Errors.showInFreeTransactionListLoader.ErrorTitle',
                    'Error has occurred',
                ),
                description:
                    this.showInFreeTransactionListLoader.errorData.violations
                        ?.map((x) => x.message)
                        .join('\r\n'),
            });
        } else {
            notification.success({
                message: t(
                    'Message.showInFreeTransactionListLoader.Success',
                    'The action successfully completed',
                ),
                description:
                    this.showInFreeTransactionListLoader.errorData?.details,
            });
        }

        await this.loadCurrentTransactionItem(transactionId);
    }
}

export const TransactionDetailsDialogViewModel =
    new TransactionDetailsDialogViewModelClass();

const isConstraintViolationProblem = (
    value: any,
): value is ConstraintViolationProblem => {
    return Array.isArray(value.violations);
};

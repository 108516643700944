/* eslint-disable max-lines */
import { Col, Form, Modal as AntModal, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { FilterForm } from 'src/components/Form/FilterForm';
import { HiddenField } from 'src/components/Form/HiddenField';
import { NumberField } from 'src/components/Form/NumberField';
import { TextField } from 'src/components/Form/TextField';
import { ListView } from 'src/components/ListView/ListView';
import { Modal } from 'src/components/Modal/Modal';
import { EntityModelTransactionListItem, Loan } from 'src/generated-api-client';
import { ConnectFreeTransactionsDialogViewModel } from 'src/pages/LoanPage/ConnectFreeTransactionsDialogViewModel';
import { TransactionDetailsDialog } from 'src/pages/TransactionsPage/TransactionDetailsDialog';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { StringHelper } from 'src/utils/StringHelper';
import styled from 'styled-components';

export type ConnectFreeTransactionsDialogProps = ModalRendererProps<{
    loan: Loan;
}>;

export const ConnectFreeTransactionsDialogFn = observer(
    ({ loan, ...props }: ConnectFreeTransactionsDialogProps) => {
        const [modalForm] = Form.useForm();
        useEffect(() => {
            ConnectFreeTransactionsDialogViewModel.init();

            return () => {
                ConnectFreeTransactionsDialogViewModel.reset();
            };
        }, []);
        const selectedRowKeys = toJS(
            ConnectFreeTransactionsDialogViewModel.selectedItemIds,
        );

        return (
            <Modal
                {...props}
                title={t('Dialog.ConnectFreeTransactionsDialog.Title')}
                width="90wv"
                onFinish={() => {
                    return new Promise((resolve, reject) => {
                        AntModal.confirm({
                            title: t(
                                'Dialog.ConnectFreeTransactionsDialog.Confirm.Title',
                                'Confirm operation',
                            ),
                            content: t(
                                'Dialog.ConnectFreeTransactionsDialog.Confirm.Content',
                            ),
                            onOk: async () => {
                                await ConnectFreeTransactionsDialogViewModel.connectSelectedTransactions(
                                    loan.id,
                                );
                                resolve({});
                            },
                            onCancel: () => {
                                reject();
                            },
                        });
                    });
                }}
                form={modalForm}
                isLoading={
                    ConnectFreeTransactionsDialogViewModel
                        .connectSelectedTransactionsLoader.isLoading
                }
                okButtonProps={{
                    disabled:
                        ConnectFreeTransactionsDialogViewModel.countSelectedItems ===
                        0,
                }}
                okText={t('Dialog.ConnectFreeTransactionsDialog.Ok', 'Connect')}
            >
                <Card
                    title={t(
                        'Dialog.Dialog.ConnectFreeTransactionsDialog.LoanSection.Title',
                        'Loan info',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                value={loan.applicationNumber}
                                label={t(
                                    'Entity.Loan.ApplicationNumber',
                                    'Loan №',
                                )}
                                format="string"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan.companyInfo.name}
                                label={t(
                                    'Entity.Company.CompanyName',
                                    'Company',
                                )}
                                format="string"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan.companyInfo.identity}
                                label={t(
                                    'Entity.Company.CompanyIdentity',
                                    'UTR/CRN',
                                )}
                                format="string"
                            />
                        </Col>
                    </Row>
                </Card>
                <Card
                    title={t(
                        'Dialog.Dialog.ConnectFreeTransactionsDialog.Payment.Title',
                        'Payment',
                    )}
                >
                    <Row gutter={16}>
                        <Col xs={12}>
                            <DataView
                                value={loan.nextPayment?.amount}
                                label={t(
                                    'Entity.Loan.NextPayment.Amount',
                                    'Payment amount',
                                )}
                                format="money"
                            />
                        </Col>
                        <Col xs={12}>
                            <DataView
                                value={loan.nextPayment?.date}
                                label={t(
                                    'Entity.Loan.NextPayment.Date',
                                    'Repayment Schedule Date',
                                )}
                                format="date"
                            />
                        </Col>
                    </Row>
                </Card>
                <Card
                    title={t(
                        'Dialog.ConnectFreeTransactionsDialog.FreeTransactionsList.Title',
                        'Free transactions',
                    )}
                >
                    <StyledFilterForm
                        store={TransactionsStore}
                        initialValues={{ showFreeTransactions: true }}
                        onFilter={() => {
                            ConnectFreeTransactionsDialogViewModel.clearSelectedTransactions();
                        }}
                    >
                        <TextField
                            name="transactionId"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.TransactionId',
                            )}
                        />
                        <TextField
                            name="comment"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.Comment',
                            )}
                        />
                        <NumberField
                            name="amountFrom"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.AmountFrom',
                            )}
                        />
                        <NumberField
                            name="amountTo"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.AmountTo',
                            )}
                        />
                        <DatePickerField
                            name="updatedAtAfter"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.UpdatedAtAfter',
                            )}
                        />
                        <DatePickerField
                            name="updatedAtBefore"
                            label={t(
                                'Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Filter.UpdatedAtBefore',
                            )}
                        />

                        <HiddenField name="showFreeTransactions" />
                    </StyledFilterForm>
                    <FreeTransactionsSummary />
                    <ListView
                        store={TransactionsStore}
                        columns={columns}
                        loading={TransactionsStore.listLoader.isLoading}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onSelect: (item, selected) => {
                                if (selected) {
                                    ConnectFreeTransactionsDialogViewModel.selectItem(
                                        item,
                                    );
                                } else {
                                    ConnectFreeTransactionsDialogViewModel.unselectItem(
                                        item,
                                    );
                                }
                            },
                            onSelectAll: (
                                selected,
                                selectedItems,
                                unselectedItems,
                            ) => {
                                if (selected) {
                                    ConnectFreeTransactionsDialogViewModel.selectItems(
                                        selectedItems,
                                    );
                                } else {
                                    ConnectFreeTransactionsDialogViewModel.unselectItems(
                                        unselectedItems,
                                    );
                                }
                            },
                            onSelectMultiple: (
                                selected,
                                selectedItems,
                                unselectedItems,
                                // eslint-disable-next-line sonarjs/no-identical-functions
                            ) => {
                                if (selected) {
                                    ConnectFreeTransactionsDialogViewModel.selectItems(
                                        selectedItems,
                                    );
                                } else {
                                    ConnectFreeTransactionsDialogViewModel.unselectItems(
                                        unselectedItems,
                                    );
                                }
                            },
                        }}
                    />
                    <FreeTransactionsSummary />
                </Card>
            </Modal>
        );
    },
);

export const ConnectFreeTransactionsDialog = addModalRenderer(
    ConnectFreeTransactionsDialogFn,
);

const FreeTransactionsSummary = observer(() => {
    return (
        <Row gutter={16}>
            <Col flex={1}>
                {t(
                    'Dialog.ConnectFreeTransactionsDialog.Summary.SelectedTransactionsCount',
                    {
                        count: ConnectFreeTransactionsDialogViewModel.countSelectedItems,
                    },
                )}{' '}
                <Typography.Link
                    onClick={() => {
                        ConnectFreeTransactionsDialogViewModel.clearSelectedTransactions();
                    }}
                >
                    {t(
                        'Dialog.ConnectFreeTransactionsDialog.Summary.ClearSelectedTransactions',
                        'Clear selected',
                    )}
                </Typography.Link>
            </Col>
            <Col>
                <DataView
                    label={t(
                        'Dialog.ConnectFreeTransactionsDialog.Summary.TotalAmount',
                        'Total:',
                    )}
                    value={ConnectFreeTransactionsDialogViewModel.sumAmount}
                    format="money"
                    align="horizontal"
                />
            </Col>
        </Row>
    );
});

const StyledFilterForm = styled(FilterForm)`
    margin-bottom: 20px;
`;

const columns: ColumnsType<EntityModelTransactionListItem> = [
    {
        key: 'transactionId',
        dataIndex: 'transactionId',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Id" />
        ),
        render: (value, record) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => (
                        <Typography.Link
                            onClick={() => {
                                TransactionDetailsDialog.render({
                                    transactionId: record.id,
                                });
                            }}
                        >
                            {StringHelper.sliceFirstWithEllipsis(value, 6)}
                        </Typography.Link>
                    )}
                    tooltip={value}
                />
            );
        },
        width: 100,
    },
    {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Date" />
        ),
        render: (value) => {
            return <DataView value={value} format="date" hideLabel />;
        },
        sorter: true,
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Amount" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="money" />;
        },
        sorter: true,
    },

    {
        key: 'comment',
        dataIndex: 'comment',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.Comment" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
    },

    {
        key: 'companyIdentity',
        dataIndex: 'companyIdentity',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.CompanyIdentity" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
    },
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.CompanyName" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
    },
    {
        key: 'applicationNumber',
        dataIndex: 'applicationNumber',
        title: (
            <Trans i18nKey="Dialog.ConnectFreeTransactionsDialog.FreeTransactions.ApplicationNumber" />
        ),
        render: (value) => {
            return <DataView hideLabel value={value} format="string" />;
        },
        sorter: true,
    },
];

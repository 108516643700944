import { action, computed, observable } from 'mobx';
import i18next, { initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
export const SUPPORTED_LOCALES = ['en'];

type Authentication = {
    clientId?: string;
    realm?: string;
    url?: string;
};

type UiSettings = {
    authentication?: Authentication;
};

export class SettingsStoreClass {
    private settingsLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(uiSettingsApi.getSettings()),
    );

    private backendInfoLoader = new AsyncOperationWithStatus(() =>
        Promise.resolve(),
    );

    @observable currentLocale?: string;

    @action async load() {
        await this.settingsLoader.call();
        await this.backendInfoLoader.call();
    }

    @computed get settings(): UiSettings | undefined {
        return this.settingsLoader.data;
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action async setupInternationalization() {
        await initInternationalization((this.settings || {}) as any);
    }

    @computed get supportedLocales() {
        return SUPPORTED_LOCALES;
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        await i18next.changeLanguage(locale);
    }

    @computed get backEndServerVersion() {
        return 'coming soon'; // TODO: add backend version
    }
}

export const SettingsStore = new SettingsStoreClass();

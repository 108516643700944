/* eslint-disable sonarjs/cognitive-complexity */
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

const { Option } = Select;

// eslint-disable-next-line react/require-default-props
export type SelectFieldProps = FormItemProps &
    // eslint-disable-next-line react/require-default-props
    Omit<SelectProps, 'options'> & {
        // eslint-disable-next-line react/require-default-props
        options?: OptionProps[];
        store?: BasicStore<any>;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        convertListItemToOption?: (item?: any) => OptionProps;
        searchValueAsOption?: boolean;
    };

export const SelectField = observer(
    ({
        name,
        label,
        options,
        allowClear = true,
        showArrow = true,
        mode,
        disabled,
        store,
        convertListItemToOption,
        onChange,
        showSearch,
        onSearch,
        searchValueAsOption,
        ...props
    }: SelectFieldProps) => {
        const [searchValue, setSearchValue] = useState<string>(
            showSearch ? props.value : '',
        );

        useEffect(() => {
            if (store && !store.listLoader.isLoaded) {
                store.loadList();
                if (!convertListItemToOption) {
                    console.error(
                        'No callback function getOptionsList. Please use the function to render options',
                    );
                }
            }

            return () => {
                // store && store.listLoader.reset();
            };
        }, []);

        const optionList = useMemo(() => {
            const storeOptionsList: OptionProps[] =
                store?.list.length && convertListItemToOption
                    ? store?.list.map(convertListItemToOption)
                    : [];
            const result = [...(options || storeOptionsList)];

            if (searchValueAsOption && searchValue) {
                result.push({ value: searchValue, children: searchValue });
            }

            return result;
        }, [options, store?.list, searchValueAsOption, searchValue]);

        return (
            <Form.Item {...props} name={name} label={label}>
                <Select
                    loading={store?.listLoader.isLoading}
                    allowClear={allowClear}
                    showArrow={showArrow}
                    mode={mode}
                    disabled={disabled}
                    onChange={onChange}
                    showSearch={showSearch}
                    onSearch={(value) => {
                        showSearch && setSearchValue(value);
                        onSearch?.(value);
                    }}
                >
                    {optionList?.map((option: OptionProps) => (
                        <Option key={option.value} value={option.value}>
                            {option.children}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
    },
);

export const getOptionsFromEnum = (
    i18nKeyPrefix: string,
    values: Record<string, any>,
): OptionProps[] => {
    return Object.values(values).map((value) => ({
        value,
        children: <Trans i18nKey={`${i18nKeyPrefix}.${value}`} />,
    }));
};

import { Col, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { Modal } from 'src/components/Modal/Modal';
import { EntityModelNotificationListItem } from 'src/generated-api-client';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

export type NotificationDetailsDialogProps = ModalRendererProps<{
    notification: EntityModelNotificationListItem;
}>;
export const NotificationDetailsDialogFn = observer(
    ({ notification, ...props }: NotificationDetailsDialogProps) => {
        return (
            <StyledModal
                {...props}
                footer={false}
                title={t('Dialog.Notification.Details.Title')}
                width="70%"
            >
                <Row gutter={16}>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Notification.CreatedAt')}
                            value={notification.createdAt}
                            format="date-time-seconds"
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Notification.Type')}
                            value={notification.type}
                            format={(value) => {
                                return (
                                    <EnumView
                                        enumName="NOTIFICATION_TYPE"
                                        value={value}
                                    />
                                );
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Notification.Recipient')}
                            value={notification.recipient}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Notification.Status')}
                            value={notification.status}
                            format={(value) => {
                                return (
                                    <EnumView
                                        enumName="NOTIFICATION_STATUS"
                                        value={value}
                                    />
                                );
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <DataView
                            label={t('Entity.Notification.Error')}
                            value={notification.error}
                        />
                    </Col>
                    <Col xs={24}>
                        {isHtmlContent(notification.content) ? (
                            <StyledDataViewContent
                                // eslint-disable-next-line sonarjs/no-duplicate-string
                                label={t('Entity.Notification.Content')}
                                value={notification.content}
                                format={(value) => {
                                    return (
                                        <StyledIframe
                                            src={convertContentToBase64Url(
                                                value,
                                            )}
                                            title={t(
                                                'Entity.Notification.Content',
                                            )}
                                        />
                                    );
                                }}
                            />
                        ) : (
                            <StyledDataViewContent
                                label={t('Entity.Notification.Content')}
                                value={notification.content}
                                format={(value) => {
                                    return <StyledValue>{value}</StyledValue>;
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </StyledModal>
        );
    },
);

const StyledValue = styled.div`
    white-space: pre;
`;

const StyledIframe = styled.iframe`
    border: none;
    width: 100%;
    height: 500px;
`;

const StyledDataViewContent = styled(DataView)`
    width: 100%;
    .app-data-view__value {
        width: 100%;
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }
        }
    }
`;

const convertContentToBase64Url = (value: string) => {
    const blob = new Blob([value], { type: 'text/html; charset=utf-8' });

    return URL.createObjectURL(blob);
};

const HTML_PATTERN = /<!DOCTYPE/gim;

const isHtmlContent = (value: string) => {
    return HTML_PATTERN.test(value);
};

export const NotificationDetailsDialog = addModalRenderer(
    NotificationDetailsDialogFn,
);

const StyledModal = styled(Modal)`
    top: 40px;
`;

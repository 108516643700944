import { message, notification } from 'antd';
import copy from 'copy-to-clipboard';
import { t } from 'i18next';

interface CopyToClipboardOptions {
    notifyWhenCopied?: boolean;
    message?: React.ReactNode;
}
export class DataHelper {
    static copyToClipboard(data: string, options?: CopyToClipboardOptions) {
        if ((copy(data) && options?.notifyWhenCopied) || message) {
            const message =
                options?.message ||
                t(
                    'Utils.DataHelper.DataWasCopiedSuccessfully',
                    'Data was copied successfully',
                );
            notification.success({
                message,
            });
        }
    }
}

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { observer } from 'mobx-react-lite';
import { Route, Routes } from 'react-router-dom';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import styled from 'styled-components';

export const RoutesLayout = observer(() => {
    // const router = useMemo(() => {
    //     return createBrowserRouter(RouterStore.routes);
    // }, [RouterStore.routes]);

    return (
        <ErrorBoundary>
            <Routes>
                {/* //<Route path={RoutePaths.index} element={() => <>index</>}/> */}
                {RouterStore.routes.map((route) => {
                    const Element = (
                        route.available === false
                            ? NotAccessPage
                            : route.element
                    ) as React.ComponentClass;

                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<Element />}
                        />
                    );
                })}

                {/* <Route path="*" element={<NotFound/>} /> */}
            </Routes>
        </ErrorBoundary>
    );
});

const NotFound = () => <div>Not found</div>;
const NotAccessPage = () => <div>Not access</div>;
const StyledWrapper = styled.div`
    position: relative;
    padding: 20px;
`;

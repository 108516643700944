import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { FilterForm } from 'src/components/Form/FilterForm';
import { ListView } from 'src/components/ListView/ListView';
import { EntityModelEventListItem } from 'src/generated-api-client';
import { LogDetailsDialog } from 'src/pages/LoanPage/LogDetailsDialog';
import { LoanEventsStore } from 'src/stores/EventsStore/EventsStore';

export type LogsTabProps = {
    loanId: number;
};
export const LogsTab = observer(({ loanId }: LogsTabProps) => {
    const store = LoanEventsStore.getInstance(loanId);
    useEffect(() => {
        store.loadList();

        return () => {
            store.listLoader.reset();
            store.pager.reset();
        };
    }, [loanId]);

    return (
        <Card title={t('Loan.Events.Title', 'Events')}>
            <FilterForm store={store} search />
            <ListView
                store={store}
                columns={columns}
                loading={store.listLoader.isLoading}
                onRowClick={(_e, record) => {
                    LogDetailsDialog.render({ event: record });
                }}
            />
        </Card>
    );
});

const columns: ColumnsType<EntityModelEventListItem> = [
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Loan.Events.Items.CreatedAt" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="date-time" />;
        },
        sorter: true,
    },

    {
        key: 'actor',
        dataIndex: 'actor',
        title: <Trans i18nKey="Loan.Events.Items.Actor" />,
        render: (value, record) => {
            return (
                <DataView
                    hideLabel
                    value={value}
                    format={(value) => {
                        return (
                            <span>
                                <EnumView enumName="ACTOR" value={value} />{' '}
                                {record.actorName}
                            </span>
                        );
                    }}
                />
            );
        },
        sorter: true,
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Loan.Events.Items.Type" />,
        render: (value) => {
            return (
                <DataView
                    value={value}
                    hideLabel
                    format={(value) => {
                        return <EnumView enumName="EVENT_TYPE" value={value} />;
                    }}
                />
            );
        },
        sorter: true,
    },
    {
        key: 'details',
        dataIndex: 'details',
        title: <Trans i18nKey="Loan.Events.Items.Details" />,
        render: (value) => {
            return (
                <Typography.Paragraph ellipsis={{ rows: 3 }}>
                    {JSON.stringify(value, null, 2)}
                </Typography.Paragraph>
            );
        },
        width: '25%',
    },
];

t(`Entity.Event.Type.LOAN_DISBURSED`, 'Loan disbursed');
t(`Entity.Event.Type.LOAN_STATUS_CHANGED`, 'Loan status changed');
t(`Entity.Event.Type.SIGN_CONTRACT`, 'Signing contract ');
t(`Entity.Event.Type.ECOLO_SENT_EVENT`, 'Sent to Ecolo');
t(`Entity.Event.Type.NOTIFICATION_SENT`, 'Notification sent');
t(`Entity.Event.Type.DOWNLOAD_CONTRACT`, 'Contract downloaded');
t(
    `Entity.Event.Type.TRANSACTIONS_CONNECTED`,
    'Transaction connected with a payment',
);
t(`Entity.Event.Type.LOAN_CREATED`, 'Loan created');
t(`Entity.Event.Type.LOAN_REPAID`, 'Loan repaid');
t(`Entity.Event.Type.LOAN_UPDATED`, 'Loan updated');

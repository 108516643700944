import { Button, ButtonProps, Form, Input, Popover, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchPanelFilterFormProps } from 'src/components/SearchPanel/SearchPanelFilterForm';
import { ReactComponent as IconFilterClear } from 'src/icons/filter-clear.svg';
import { ReactComponent as FilterIconActive } from 'src/icons/icon-filter-active.svg';
import { ReactComponent as FilterIcon } from 'src/icons/icon-filter.svg';
import { ReactComponent as SearchIcon } from 'src/icons/icon-search.svg';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type SearchPanelProps = {
    placeholder: string;
    store: BasicStore<any>;
    renderPanel?: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props?: SearchPanelFilterFormProps,
    ) => React.ReactElement<SearchPanelFilterFormProps>;
};

type PopperElement = { close: () => void };

export const SearchPanel = observer(
    ({ store, placeholder, renderPanel }: SearchPanelProps) => {
        const popover = useRef<PopperElement>();
        const { t } = useTranslation();
        const [form] = Form.useForm();

        return (
            <Form
                form={form}
                onFinish={(values) => {
                    store.filterCriteria?.applyFilter(values);
                    store.loadList();
                }}
                layout="vertical"
            >
                {store.filterCriteria && (
                    <StyledFormItem name="search">
                        <StyledInputSearch
                            addonBefore={
                                renderPanel && (
                                    <StyledPopper
                                        ref={(el: PopperElement) => {
                                            popover.current = el;
                                        }}
                                        content={renderPanel({
                                            form,
                                            closePopper: () =>
                                                popover.current?.close(),
                                        })}
                                        trigger="click"
                                        placement="bottomLeft"
                                    >
                                        <StyledFilterButton
                                            hasFilter={
                                                store.filterCriteria.hasFilter
                                            }
                                        >
                                            {store.filterCriteria.hasFilter ? (
                                                <FilterIconActive />
                                            ) : (
                                                <FilterIcon />
                                            )}
                                        </StyledFilterButton>
                                    </StyledPopper>
                                )
                            }
                            addonAfter={
                                store.filterCriteria.hasFilter && (
                                    <Tooltip
                                        title={t(
                                            'Components.Filter.Button.Clear',
                                        )}
                                    >
                                        <StyledClearFilterButton
                                            type="link"
                                            icon={<IconFilterClear />}
                                            onClick={() => {
                                                form.resetFields();
                                                store.filterCriteria?.resetFilter();
                                                store.loadList();
                                            }}
                                        />
                                    </Tooltip>
                                )
                            }
                            placeholder={placeholder}
                            prefix={<SearchIcon />}
                        />
                    </StyledFormItem>
                )}
            </Form>
        );
    },
);

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`;

const StyledFilterButton = styled(Button)<
    ButtonProps & { hasFilter?: boolean }
>`
    border: none;
    background: white;
    color: ${(props) => (props.hasFilter ? '#0A65ED' : 'black')};
    border-radius: 5px;
    height: 32px;
    padding: 0 12px;

    &:active,
    &:hover,
    &:focus,
    &.active {
        background: #b9d5ff;
    }

    svg {
        margin-top: 7px;
    }
`;

const StyledInputSearch = styled(Input.Search)`
    padding: 5px;
    background: #f5f6f7;
    border-radius: 8px;

    .ant-input-search-button {
        display: none;
    }

    .ant-input-group-addon {
        padding: 0;
        border: none;
        background: transparent;
    }

    .ant-input-affix-wrapper {
        background: transparent;
        border: none;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none;
    }

    .ant-input {
        background: transparent;
    }

    .anticon.ant-input-clear-icon-hidden {
        visibility: visible;
    }
`;

export const StyledPopper = styled(Popover)`
    .ant-popover-inner {
        border-radius: 6px;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        border-radius: 8px;
    }
`;

const StyledClearFilterButton = styled(Button)`
    padding: 0;
    height: auto;
    &:hover {
        svg {
            circle {
                fill: #787879 !important;
            }
        }
    }

    svg {
        transform: translateY(4px);
    }
`;

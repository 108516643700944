import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { EnumView } from 'src/components/EnumView/EnumView';
import { ListView } from 'src/components/ListView/ListView';
import { EntityModelNotificationListItem } from 'src/generated-api-client';
import { NotificationDetailsDialog } from 'src/pages/LoanPage/NotificationDetailsDialog';
import { LoanNotificationsStore } from 'src/stores/LoanNotificationsStore/LoanNotificationsStore';

export type NotificationsTabProps = {
    loanId: number;
};
export const NotificationsTab = observer(
    ({ loanId }: NotificationsTabProps) => {
        const store = LoanNotificationsStore.getInstance(loanId);
        useEffect(() => {
            store.loadList();

            return () => {
                store.listLoader.reset();
            };
        }, [loanId]);

        return (
            <Card title={t('Loan.Notifications.Title', 'Notifications')}>
                <ListView
                    store={store}
                    columns={columns}
                    loading={store.listLoader.isLoading}
                    onRowClick={(_e, record) => {
                        NotificationDetailsDialog.render({
                            notification: record,
                        });
                    }}
                />
            </Card>
        );
    },
);

const columns: ColumnsType<EntityModelNotificationListItem> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: <Trans i18nKey="Loan.NotificationsTab.Id" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: <Trans i18nKey="Loan.NotificationsTab.Type" />,
        render: (value) => {
            return <EnumView enumName="NOTIFICATION_TYPE" value={value} />;
        },
        sorter: {
            multiple: 1,
        },
    },

    {
        key: 'recipient',
        dataIndex: 'recipient',
        title: <Trans i18nKey="Loan.NotificationsTab.Recipient" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="string" />;
        },
        sorter: {
            multiple: 3,
        },
    },
    {
        key: 'content',
        dataIndex: 'content',
        title: <Trans i18nKey="Loan.NotificationsTab.Content" />,
        render: (value) => {
            return (
                <Typography.Paragraph ellipsis={{ rows: 3 }}>
                    {value}
                </Typography.Paragraph>
            );
        },
        width: '50%',
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: <Trans i18nKey="Loan.NotificationsTab.CreatedAt" />,
        render: (value) => {
            return <DataView value={value} hideLabel format="date-time" />;
        },
        sorter: {
            multiple: 2,
        },
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: <Trans i18nKey="Loan.NotificationsTab.Status" />,
        render: (value) => {
            return <EnumView enumName="NOTIFICATION_STATUS" value={value} />;
        },
    },
];

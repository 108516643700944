import jwtDecode from 'jwt-decode';

import { LocalStoreHelper } from './LocalStoreHelper';

const DELTA_TIME = 1 * 60 * 1000;
const TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';

export type JWT = {
    exp: number;
};

export function decode(token: string): JWT | undefined {
    try {
        return jwtDecode(token) as JWT;
    } catch (err) {}
}

export const shouldUpdateToken = (jwt = '') => {
    const decoded = decode(jwt);

    return decoded ? decoded.exp * 1000 < Date.now() - DELTA_TIME : true;
};

export const isValidToken = (token = '') => {
    return !shouldUpdateToken(token);
};

export const saveToken = (token: string) => {
    LocalStoreHelper.setItem(TOKEN, token);
};

export const getToken = () => {
    return LocalStoreHelper.getItem(TOKEN) || '';
};

export const clearToken = () => {
    localStorage.removeItem(TOKEN);
};

export const saveRefreshToken = (token: string) => {
    LocalStoreHelper.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
    return LocalStoreHelper.getItem(REFRESH_TOKEN) || '';
};
export const clearRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};

export const TokenHelper = {
    saveToken,
    getToken,
    clearToken,
    saveRefreshToken,
    getRefreshToken,
    clearRefreshToken,
    isValidToken,
    shouldUpdateToken,
    decode,
};

import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { Header } from 'src/components/Header';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import styled from 'styled-components';

export const AppLayout = observer(() => {
    if (!AppStore.started || AuthStore.isLoading) {
        return <SplashScreen />;
    }

    if (!AuthStore.authenticated) {
        return (
            <StyledLayoutContent>
                <RoutesLayout />
            </StyledLayoutContent>
        );
    }

    return (
        <StyledLayoutContent>
            <Header />
            <RoutesLayout />
        </StyledLayoutContent>
    );
});

const StyledLayoutContent = styled(Layout.Content)`
    padding: 0;
    position: relative;
`;

/* tslint:disable */
/* eslint-disable */
/**
 * Evabeta Back Office API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-dev.2.0fe1cb4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */
export enum Actor {
    BO_USER = 'BO_USER',
    EXTERNAL_API = 'EXTERNAL_API',
    SYSTEM = 'SYSTEM'
}

/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    realm?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ConnectTransactionRequest
 */
export interface ConnectTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectTransactionRequest
     */
    loanId: number;
    /**
     * 
     * @type {Set<number>}
     * @memberof ConnectTransactionRequest
     */
    transactionIds: Set<number>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblem
 */
export interface ConstraintViolationProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ConstraintViolationProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConstraintViolationProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ConstraintViolationProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ConstraintViolationProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    type?: string;
    /**
     * 
     * @type {Array<Violation>}
     * @memberof ConstraintViolationProblem
     */
    violations?: Array<Violation>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemCause
 */
export interface ConstraintViolationProblemCause {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemCause
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemStackTrace
 */
export interface ConstraintViolationProblemStackTrace {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    classLoaderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ConstraintViolationProblemStackTrace
     */
    lineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    methodName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConstraintViolationProblemStackTrace
     */
    nativeMethod?: boolean;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemSuppressed
 */
export interface ConstraintViolationProblemSuppressed {
    /**
     * 
     * @type {ConstraintViolationProblemCause}
     * @memberof ConstraintViolationProblemSuppressed
     */
    cause?: ConstraintViolationProblemCause;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemSuppressed
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @interface EntityModelEventListItem
 */
export interface EntityModelEventListItem {
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelEventListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {Actor}
     * @memberof EntityModelEventListItem
     */
    actor: Actor;
    /**
     * 
     * @type {string}
     * @memberof EntityModelEventListItem
     */
    actorName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelEventListItem
     */
    createdAt: string;
    /**
     * 
     * @type {EventDetails}
     * @memberof EntityModelEventListItem
     */
    details: EventDetails;
    /**
     * 
     * @type {number}
     * @memberof EntityModelEventListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelEventListItem
     */
    loanId?: number;
    /**
     * 
     * @type {EventType}
     * @memberof EntityModelEventListItem
     */
    type: EventType;
}
/**
 * 
 * @export
 * @interface EntityModelLoanListItem
 */
export interface EntityModelLoanListItem {
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelLoanListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanListItem
     */
    applicationNumber: string;
    /**
     * 
     * @type {LoanBank}
     * @memberof EntityModelLoanListItem
     */
    bank: LoanBank;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    bid: number;
    /**
     * 
     * @type {LoanCompanyInfo}
     * @memberof EntityModelLoanListItem
     */
    companyInfo: LoanCompanyInfo;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanListItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanListItem
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    loanAmount: number;
    /**
     * 
     * @type {LoanMemberInfo}
     * @memberof EntityModelLoanListItem
     */
    memberInfo: LoanMemberInfo;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    outstandingBalance: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    paymentAmount: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanListItem
     */
    purpose?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof EntityModelLoanListItem
     */
    status: LoanStatus;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanListItem
     */
    term: number;
}
/**
 * 
 * @export
 * @interface EntityModelNotificationListItem
 */
export interface EntityModelNotificationListItem {
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelNotificationListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationListItem
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationListItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationListItem
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelNotificationListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelNotificationListItem
     */
    loanId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelNotificationListItem
     */
    recipient: string;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof EntityModelNotificationListItem
     */
    status: NotificationStatus;
    /**
     * 
     * @type {NotificationType}
     * @memberof EntityModelNotificationListItem
     */
    type: NotificationType;
}
/**
 * 
 * @export
 * @interface EntityModelRepaymentScheduleListItem
 */
export interface EntityModelRepaymentScheduleListItem {
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    earlyPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    interest: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    loanId?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    outstandingBalance: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    overdue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    paid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    principal: number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof EntityModelRepaymentScheduleListItem
     */
    status?: PaymentStatus;
}
/**
 * 
 * @export
 * @interface EntityModelTransactionListItem
 */
export interface EntityModelTransactionListItem {
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelTransactionListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelTransactionListItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    applicationNumber?: string;
    /**
     * 
     * @type {TransactionBindingType}
     * @memberof EntityModelTransactionListItem
     */
    bindingType: TransactionBindingType;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    companyIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelTransactionListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelTransactionListItem
     */
    loanId?: number;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof EntityModelTransactionListItem
     */
    provider?: PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    repaymentScheduleDate?: string;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof EntityModelTransactionListItem
     */
    status: TransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    transactionId?: string;
    /**
     * 
     * @type {TransactionType}
     * @memberof EntityModelTransactionListItem
     */
    type: TransactionType;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTransactionListItem
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface EventDetails
 */
export interface EventDetails {
    /**
     * 
     * @type {string}
     * @memberof EventDetails
     */
    type: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    LOAN_DISBURSED = 'LOAN_DISBURSED',
    LOAN_STATUS_CHANGED = 'LOAN_STATUS_CHANGED',
    SIGN_CONTRACT = 'SIGN_CONTRACT',
    ECOLO_SENT_EVENT = 'ECOLO_SENT_EVENT',
    NOTIFICATION_SENT = 'NOTIFICATION_SENT',
    DOWNLOAD_CONTRACT = 'DOWNLOAD_CONTRACT',
    TRANSACTIONS_CONNECTED = 'TRANSACTIONS_CONNECTED',
    LOAN_CREATED = 'LOAN_CREATED',
    LOAN_REPAID = 'LOAN_REPAID',
    LOAN_UPDATED = 'LOAN_UPDATED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE'
}

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    deprecation?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    hreflang?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    profile?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    templated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    applicationNumber: string;
    /**
     * 
     * @type {LoanBank}
     * @memberof Loan
     */
    bank: LoanBank;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    bid: number;
    /**
     * 
     * @type {LoanCompanyInfo}
     * @memberof Loan
     */
    companyInfo: LoanCompanyInfo;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    contractUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    loanAmount: number;
    /**
     * 
     * @type {LoanMemberInfo}
     * @memberof Loan
     */
    memberInfo: LoanMemberInfo;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Loan
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {LoanPayment}
     * @memberof Loan
     */
    nextPayment?: LoanPayment;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    outstandingBalance: number;
    /**
     * 
     * @type {LoanOverdue}
     * @memberof Loan
     */
    overdue?: LoanOverdue;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    paymentAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    purpose?: string;
    /**
     * 
     * @type {LoanStatus}
     * @memberof Loan
     */
    status: LoanStatus;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    term: number;
    /**
     * 
     * @type {LoanTransactions}
     * @memberof Loan
     */
    transactions?: LoanTransactions;
}
/**
 * 
 * @export
 * @interface LoanBank
 */
export interface LoanBank {
    /**
     * 
     * @type {string}
     * @memberof LoanBank
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBank
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBank
     */
    sortCode: string;
}
/**
 * 
 * @export
 * @interface LoanCompanyInfo
 */
export interface LoanCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    identity: string;
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    regDate: string;
    /**
     * 
     * @type {string}
     * @memberof LoanCompanyInfo
     */
    regForm: string;
}
/**
 * 
 * @export
 * @interface LoanMemberInfo
 */
export interface LoanMemberInfo {
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    fullName: string;
    /**
     * 
     * @type {Gender}
     * @memberof LoanMemberInfo
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    patronymic?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanMemberInfo
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface LoanOverdue
 */
export interface LoanOverdue {
    /**
     * 
     * @type {number}
     * @memberof LoanOverdue
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanOverdue
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanOverdue
     */
    principal?: number;
}
/**
 * 
 * @export
 * @interface LoanPayment
 */
export interface LoanPayment {
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    date?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof LoanPayment
     */
    status?: PaymentStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanStatus {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING'
}

/**
 * 
 * @export
 * @interface LoanTransactions
 */
export interface LoanTransactions {
    /**
     * 
     * @type {number}
     * @memberof LoanTransactions
     */
    freeTransactionCount?: number;
}
/**
 * 
 * @export
 * @interface Localization
 */
export interface Localization {
    /**
     * 
     * @type {string}
     * @memberof Localization
     */
    en?: string;
}
/**
 * 
 * @export
 * @interface LocalizedEnum
 */
export interface LocalizedEnum {
    /**
     * 
     * @type {string}
     * @memberof LocalizedEnum
     */
    key?: string;
    /**
     * 
     * @type {Localization}
     * @memberof LocalizedEnum
     */
    l10n?: Localization;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type?: MessageTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageTypeEnum {
    ALL_PAYMENTS_FILLED = 'ALL_PAYMENTS_FILLED',
    DUE_DATE = 'DUE_DATE',
    PAYMENT_OVERDUE = 'PAYMENT_OVERDUE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationStatus {
    ERROR = 'ERROR',
    SENT = 'SENT'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    EMAIL = 'EMAIL',
    SMS = 'SMS'
}

/**
 * 
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelEventListItem
 */
export interface PagedModelEntityModelEventListItem {
    /**
     * 
     * @type {PagedModelEntityModelEventListItemEmbedded}
     * @memberof PagedModelEntityModelEventListItem
     */
    _embedded?: PagedModelEntityModelEventListItemEmbedded;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelEventListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelEventListItem
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelEventListItemEmbedded
 */
export interface PagedModelEntityModelEventListItemEmbedded {
    /**
     * 
     * @type {Array<EntityModelEventListItem>}
     * @memberof PagedModelEntityModelEventListItemEmbedded
     */
    eventListItems?: Array<EntityModelEventListItem>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanListItem
 */
export interface PagedModelEntityModelLoanListItem {
    /**
     * 
     * @type {PagedModelEntityModelLoanListItemEmbedded}
     * @memberof PagedModelEntityModelLoanListItem
     */
    _embedded?: PagedModelEntityModelLoanListItemEmbedded;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelLoanListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelLoanListItem
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanListItemEmbedded
 */
export interface PagedModelEntityModelLoanListItemEmbedded {
    /**
     * 
     * @type {Array<EntityModelLoanListItem>}
     * @memberof PagedModelEntityModelLoanListItemEmbedded
     */
    loanListItems?: Array<EntityModelLoanListItem>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelNotificationListItem
 */
export interface PagedModelEntityModelNotificationListItem {
    /**
     * 
     * @type {PagedModelEntityModelNotificationListItemEmbedded}
     * @memberof PagedModelEntityModelNotificationListItem
     */
    _embedded?: PagedModelEntityModelNotificationListItemEmbedded;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelNotificationListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelNotificationListItem
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelNotificationListItemEmbedded
 */
export interface PagedModelEntityModelNotificationListItemEmbedded {
    /**
     * 
     * @type {Array<EntityModelNotificationListItem>}
     * @memberof PagedModelEntityModelNotificationListItemEmbedded
     */
    notificationListItems?: Array<EntityModelNotificationListItem>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelRepaymentScheduleListItem
 */
export interface PagedModelEntityModelRepaymentScheduleListItem {
    /**
     * 
     * @type {PagedModelEntityModelRepaymentScheduleListItemEmbedded}
     * @memberof PagedModelEntityModelRepaymentScheduleListItem
     */
    _embedded?: PagedModelEntityModelRepaymentScheduleListItemEmbedded;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelRepaymentScheduleListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelRepaymentScheduleListItem
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelRepaymentScheduleListItemEmbedded
 */
export interface PagedModelEntityModelRepaymentScheduleListItemEmbedded {
    /**
     * 
     * @type {Array<EntityModelRepaymentScheduleListItem>}
     * @memberof PagedModelEntityModelRepaymentScheduleListItemEmbedded
     */
    repaymentScheduleListItems?: Array<EntityModelRepaymentScheduleListItem>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelTransactionListItem
 */
export interface PagedModelEntityModelTransactionListItem {
    /**
     * 
     * @type {PagedModelEntityModelTransactionListItemEmbedded}
     * @memberof PagedModelEntityModelTransactionListItem
     */
    _embedded?: PagedModelEntityModelTransactionListItemEmbedded;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelTransactionListItem
     */
    _links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelTransactionListItem
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelTransactionListItemEmbedded
 */
export interface PagedModelEntityModelTransactionListItemEmbedded {
    /**
     * 
     * @type {Array<EntityModelTransactionListItem>}
     * @memberof PagedModelEntityModelTransactionListItemEmbedded
     */
    transactionListItems?: Array<EntityModelTransactionListItem>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentProvider {
    ACQUIRED = 'ACQUIRED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStatus {
    OVERDUE = 'OVERDUE',
    PAID = 'PAID',
    UNPAID = 'UNPAID'
}

/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Problem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {StatusType}
     * @memberof Problem
     */
    status?: StatusType;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface StatusType
 */
export interface StatusType {
    /**
     * 
     * @type {string}
     * @memberof StatusType
     */
    reasonPhrase?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusType
     */
    statusCode?: number;
}
/**
 * 
 * @export
 * @interface ThrowableProblem
 */
export interface ThrowableProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ThrowableProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ThrowableProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ThrowableProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ThrowableProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ThrowableProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {Array<TransactionAction>}
     * @memberof Transaction
     */
    actions?: Array<TransactionAction>;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    applicationNumber?: string;
    /**
     * 
     * @type {TransactionBank}
     * @memberof Transaction
     */
    bank?: TransactionBank;
    /**
     * 
     * @type {TransactionBindingType}
     * @memberof Transaction
     */
    bindingType: TransactionBindingType;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    comment?: string;
    /**
     * 
     * @type {TransactionCompanyInfo}
     * @memberof Transaction
     */
    companyInfo?: TransactionCompanyInfo;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    logJson?: string;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof Transaction
     */
    provider?: PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    repaymentScheduleDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    repaymentScheduleExternalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    showTransaction?: boolean;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof Transaction
     */
    status: TransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionId?: string;
    /**
     * 
     * @type {TransactionType}
     * @memberof Transaction
     */
    type: TransactionType;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface TransactionAction
 */
export interface TransactionAction {
    /**
     * 
     * @type {TransactionActionType}
     * @memberof TransactionAction
     */
    type?: TransactionActionType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionActionType {
    DISPLAY_TRANSACTION = 'DISPLAY_TRANSACTION',
    HIDE_TRANSACTION = 'HIDE_TRANSACTION'
}

/**
 * 
 * @export
 * @interface TransactionBank
 */
export interface TransactionBank {
    /**
     * 
     * @type {string}
     * @memberof TransactionBank
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBank
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBank
     */
    sortCode?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionBindingType {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
    UNCONNECTED = 'UNCONNECTED'
}

/**
 * 
 * @export
 * @interface TransactionCompanyInfo
 */
export interface TransactionCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof TransactionCompanyInfo
     */
    identity?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCompanyInfo
     */
    name?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatus {
    CANCELLED = 'CANCELLED',
    CONTACT_SUPPORT = 'CONTACT_SUPPORT',
    DECLINED = 'DECLINED',
    ERROR = 'ERROR',
    EXPIRED = 'EXPIRED',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
    PENDING = 'PENDING',
    QUARANTINED = 'QUARANTINED',
    SUCCESS = 'SUCCESS',
    SYSTEM_ERROR = 'SYSTEM_ERROR'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionType {
    DISBURSEMENT = 'DISBURSEMENT',
    REPAYMENT = 'REPAYMENT'
}

/**
 * 
 * @export
 * @interface UiSettings
 */
export interface UiSettings {
    /**
     * 
     * @type {Authentication}
     * @memberof UiSettings
     */
    authentication?: Authentication;
}
/**
 * 
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    message?: string;
}

/**
 * EnumsControllerApi - axios parameter creator
 * @export
 */
export const EnumsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE'} enumType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumValues: async (enumType: 'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enumType' is not null or undefined
            if (enumType === null || enumType === undefined) {
                throw new RequiredError('enumType','Required parameter enumType was null or undefined when calling getEnumValues.');
            }
            const localVarPath = `/api/enums/{enumType}`
                .replace(`{${"enumType"}}`, encodeURIComponent(String(enumType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumsControllerApi - functional programming interface
 * @export
 */
export const EnumsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE'} enumType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnumValues(enumType: 'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocalizedEnum>>> {
            const localVarAxiosArgs = await EnumsControllerApiAxiosParamCreator(configuration).getEnumValues(enumType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EnumsControllerApi - factory interface
 * @export
 */
export const EnumsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE'} enumType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumValues(enumType: 'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE', options?: any): AxiosPromise<Array<LocalizedEnum>> {
            return EnumsControllerApiFp(configuration).getEnumValues(enumType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEnumValues operation in EnumsControllerApi.
 * @export
 * @interface EnumsControllerApiGetEnumValuesRequest
 */
export interface EnumsControllerApiGetEnumValuesRequest {
    /**
     * 
     * @type {'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE'}
     * @memberof EnumsControllerApiGetEnumValues
     */
    readonly enumType: 'ACTOR' | 'CONTRACT_STATUS' | 'ECOLO_EVENT_TYPE' | 'EVENT_TYPE' | 'GENDER' | 'LOAN_STATUS' | 'MESSAGE_TYPE' | 'NOTIFICATION_STATUS' | 'NOTIFICATION_TYPE' | 'PAYMENT_STATUS' | 'TRANSACTION_BINDING_TYPE' | 'TRANSACTION_STATUS' | 'TRANSACTION_TYPE'
}

/**
 * EnumsControllerApi - object-oriented interface
 * @export
 * @class EnumsControllerApi
 * @extends {BaseAPI}
 */
export class EnumsControllerApi extends BaseAPI {
    /**
     * 
     * @param {EnumsControllerApiGetEnumValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsControllerApi
     */
    public getEnumValues(requestParameters: EnumsControllerApiGetEnumValuesRequest, options?: any) {
        return EnumsControllerApiFp(this.configuration).getEnumValues(requestParameters.enumType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (page?: number, size?: number, sort?: Array<string>, search?: string, loanId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(page?: number, size?: number, sort?: Array<string>, search?: string, loanId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelEventListItem>> {
            const localVarAxiosArgs = await EventControllerApiAxiosParamCreator(configuration).getEvents(page, size, sort, search, loanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(page?: number, size?: number, sort?: Array<string>, search?: string, loanId?: number, options?: any): AxiosPromise<PagedModelEntityModelEventListItem> {
            return EventControllerApiFp(configuration).getEvents(page, size, sort, search, loanId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEvents operation in EventControllerApi.
 * @export
 * @interface EventControllerApiGetEventsRequest
 */
export interface EventControllerApiGetEventsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof EventControllerApiGetEvents
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof EventControllerApiGetEvents
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof EventControllerApiGetEvents
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof EventControllerApiGetEvents
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof EventControllerApiGetEvents
     */
    readonly loanId?: number
}

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * 
     * @param {EventControllerApiGetEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEvents(requestParameters: EventControllerApiGetEventsRequest = {}, options?: any) {
        return EventControllerApiFp(this.configuration).getEvents(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.loanId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoanControllerApi - axios parameter creator
 * @export
 */
export const LoanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} [accessToken] Only jwt token without Bearer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (id: number, accessToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContract.');
            }
            const localVarPath = `/api/loans/{id}/contract`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLoan.');
            }
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [identity] 
         * @param {Array<LoanStatus>} [statuses] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans: async (page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, identity?: string, statuses?: Array<LoanStatus>, createdAtAfter?: string, createdAtBefore?: string, search?: string, loanId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (applicationNumber !== undefined) {
                localVarQueryParameter['applicationNumber'] = applicationNumber;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAtAfter'] = (createdAtAfter as any instanceof Date) ?
                    (createdAtAfter as any).toISOString().substr(0,10) :
                    createdAtAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAtBefore'] = (createdAtBefore as any instanceof Date) ?
                    (createdAtBefore as any).toISOString().substr(0,10) :
                    createdAtBefore;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanControllerApi - functional programming interface
 * @export
 */
export const LoanControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} [accessToken] Only jwt token without Bearer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(id: number, accessToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LoanControllerApiAxiosParamCreator(configuration).getContract(id, accessToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loan>> {
            const localVarAxiosArgs = await LoanControllerApiAxiosParamCreator(configuration).getLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [identity] 
         * @param {Array<LoanStatus>} [statuses] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoans(page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, identity?: string, statuses?: Array<LoanStatus>, createdAtAfter?: string, createdAtBefore?: string, search?: string, loanId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelLoanListItem>> {
            const localVarAxiosArgs = await LoanControllerApiAxiosParamCreator(configuration).getLoans(page, size, sort, applicationNumber, identity, statuses, createdAtAfter, createdAtBefore, search, loanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanControllerApi - factory interface
 * @export
 */
export const LoanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} [accessToken] Only jwt token without Bearer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(id: number, accessToken?: string, options?: any): AxiosPromise<any> {
            return LoanControllerApiFp(configuration).getContract(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan(id: number, options?: any): AxiosPromise<Loan> {
            return LoanControllerApiFp(configuration).getLoan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [identity] 
         * @param {Array<LoanStatus>} [statuses] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [search] 
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans(page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, identity?: string, statuses?: Array<LoanStatus>, createdAtAfter?: string, createdAtBefore?: string, search?: string, loanId?: number, options?: any): AxiosPromise<PagedModelEntityModelLoanListItem> {
            return LoanControllerApiFp(configuration).getLoans(page, size, sort, applicationNumber, identity, statuses, createdAtAfter, createdAtBefore, search, loanId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getContract operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetContractRequest
 */
export interface LoanControllerApiGetContractRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetContract
     */
    readonly id: number

    /**
     * Only jwt token without Bearer
     * @type {string}
     * @memberof LoanControllerApiGetContract
     */
    readonly accessToken?: string
}

/**
 * Request parameters for getLoan operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetLoanRequest
 */
export interface LoanControllerApiGetLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetLoan
     */
    readonly id: number
}

/**
 * Request parameters for getLoans operation in LoanControllerApi.
 * @export
 * @interface LoanControllerApiGetLoansRequest
 */
export interface LoanControllerApiGetLoansRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanControllerApiGetLoans
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanControllerApiGetLoans
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanControllerApiGetLoans
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiGetLoans
     */
    readonly applicationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiGetLoans
     */
    readonly identity?: string

    /**
     * 
     * @type {Array<LoanStatus>}
     * @memberof LoanControllerApiGetLoans
     */
    readonly statuses?: Array<LoanStatus>

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiGetLoans
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiGetLoans
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof LoanControllerApiGetLoans
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof LoanControllerApiGetLoans
     */
    readonly loanId?: number
}

/**
 * LoanControllerApi - object-oriented interface
 * @export
 * @class LoanControllerApi
 * @extends {BaseAPI}
 */
export class LoanControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoanControllerApiGetContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getContract(requestParameters: LoanControllerApiGetContractRequest, options?: any) {
        return LoanControllerApiFp(this.configuration).getContract(requestParameters.id, requestParameters.accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getLoan(requestParameters: LoanControllerApiGetLoanRequest, options?: any) {
        return LoanControllerApiFp(this.configuration).getLoan(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoanControllerApiGetLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanControllerApi
     */
    public getLoans(requestParameters: LoanControllerApiGetLoansRequest = {}, options?: any) {
        return LoanControllerApiFp(this.configuration).getLoans(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.applicationNumber, requestParameters.identity, requestParameters.statuses, requestParameters.createdAtAfter, requestParameters.createdAtBefore, requestParameters.search, requestParameters.loanId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (page?: number, size?: number, sort?: Array<string>, loanId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(page?: number, size?: number, sort?: Array<string>, loanId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelNotificationListItem>> {
            const localVarAxiosArgs = await NotificationControllerApiAxiosParamCreator(configuration).getNotifications(page, size, sort, loanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(page?: number, size?: number, sort?: Array<string>, loanId?: number, options?: any): AxiosPromise<PagedModelEntityModelNotificationListItem> {
            return NotificationControllerApiFp(configuration).getNotifications(page, size, sort, loanId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNotifications operation in NotificationControllerApi.
 * @export
 * @interface NotificationControllerApiGetNotificationsRequest
 */
export interface NotificationControllerApiGetNotificationsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof NotificationControllerApiGetNotifications
     */
    readonly loanId?: number
}

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {NotificationControllerApiGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNotifications(requestParameters: NotificationControllerApiGetNotificationsRequest = {}, options?: any) {
        return NotificationControllerApiFp(this.configuration).getNotifications(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.loanId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RepaymentScheduleControllerApi - axios parameter creator
 * @export
 */
export const RepaymentScheduleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentSchedules: async (page?: number, size?: number, sort?: Array<string>, loanId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/repayment-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepaymentScheduleControllerApi - functional programming interface
 * @export
 */
export const RepaymentScheduleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepaymentSchedules(page?: number, size?: number, sort?: Array<string>, loanId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelRepaymentScheduleListItem>> {
            const localVarAxiosArgs = await RepaymentScheduleControllerApiAxiosParamCreator(configuration).getRepaymentSchedules(page, size, sort, loanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RepaymentScheduleControllerApi - factory interface
 * @export
 */
export const RepaymentScheduleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [loanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepaymentSchedules(page?: number, size?: number, sort?: Array<string>, loanId?: number, options?: any): AxiosPromise<PagedModelEntityModelRepaymentScheduleListItem> {
            return RepaymentScheduleControllerApiFp(configuration).getRepaymentSchedules(page, size, sort, loanId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRepaymentSchedules operation in RepaymentScheduleControllerApi.
 * @export
 * @interface RepaymentScheduleControllerApiGetRepaymentSchedulesRequest
 */
export interface RepaymentScheduleControllerApiGetRepaymentSchedulesRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof RepaymentScheduleControllerApiGetRepaymentSchedules
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof RepaymentScheduleControllerApiGetRepaymentSchedules
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof RepaymentScheduleControllerApiGetRepaymentSchedules
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof RepaymentScheduleControllerApiGetRepaymentSchedules
     */
    readonly loanId?: number
}

/**
 * RepaymentScheduleControllerApi - object-oriented interface
 * @export
 * @class RepaymentScheduleControllerApi
 * @extends {BaseAPI}
 */
export class RepaymentScheduleControllerApi extends BaseAPI {
    /**
     * 
     * @param {RepaymentScheduleControllerApiGetRepaymentSchedulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepaymentScheduleControllerApi
     */
    public getRepaymentSchedules(requestParameters: RepaymentScheduleControllerApiGetRepaymentSchedulesRequest = {}, options?: any) {
        return RepaymentScheduleControllerApiFp(this.configuration).getRepaymentSchedules(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.loanId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConnectTransactionRequest} connectTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectFreeTransactions: async (connectTransactionRequest: ConnectTransactionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectTransactionRequest' is not null or undefined
            if (connectTransactionRequest === null || connectTransactionRequest === undefined) {
                throw new RequiredError('connectTransactionRequest','Required parameter connectTransactionRequest was null or undefined when calling connectFreeTransactions.');
            }
            const localVarPath = `/api/transactions/connectFreeTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof connectTransactionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(connectTransactionRequest !== undefined ? connectTransactionRequest : {}) : (connectTransactionRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayFreeTransaction: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling displayFreeTransaction.');
            }
            const localVarPath = `/api/transactions/{id}/displayFreeTransaction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTransaction.');
            }
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyIdentity] 
         * @param {string} [search] 
         * @param {string} [transactionId] 
         * @param {number} [amountFrom] 
         * @param {number} [amountTo] 
         * @param {number} [loanId] 
         * @param {boolean} [showFreeTransactions] 
         * @param {Array<TransactionStatus>} [statuses] 
         * @param {Array<TransactionType>} [types] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [repaymentScheduleDateAfter] 
         * @param {string} [repaymentScheduleDateBefore] 
         * @param {Array<TransactionBindingType>} [bindingTypes] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, companyName?: string, companyIdentity?: string, search?: string, transactionId?: string, amountFrom?: number, amountTo?: number, loanId?: number, showFreeTransactions?: boolean, statuses?: Array<TransactionStatus>, types?: Array<TransactionType>, updatedAtAfter?: string, updatedAtBefore?: string, repaymentScheduleDateAfter?: string, repaymentScheduleDateBefore?: string, bindingTypes?: Array<TransactionBindingType>, comment?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (applicationNumber !== undefined) {
                localVarQueryParameter['applicationNumber'] = applicationNumber;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (companyIdentity !== undefined) {
                localVarQueryParameter['companyIdentity'] = companyIdentity;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTo !== undefined) {
                localVarQueryParameter['amountTo'] = amountTo;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (showFreeTransactions !== undefined) {
                localVarQueryParameter['showFreeTransactions'] = showFreeTransactions;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (updatedAtAfter !== undefined) {
                localVarQueryParameter['updatedAtAfter'] = (updatedAtAfter as any instanceof Date) ?
                    (updatedAtAfter as any).toISOString().substr(0,10) :
                    updatedAtAfter;
            }

            if (updatedAtBefore !== undefined) {
                localVarQueryParameter['updatedAtBefore'] = (updatedAtBefore as any instanceof Date) ?
                    (updatedAtBefore as any).toISOString().substr(0,10) :
                    updatedAtBefore;
            }

            if (repaymentScheduleDateAfter !== undefined) {
                localVarQueryParameter['repaymentScheduleDateAfter'] = (repaymentScheduleDateAfter as any instanceof Date) ?
                    (repaymentScheduleDateAfter as any).toISOString().substr(0,10) :
                    repaymentScheduleDateAfter;
            }

            if (repaymentScheduleDateBefore !== undefined) {
                localVarQueryParameter['repaymentScheduleDateBefore'] = (repaymentScheduleDateBefore as any instanceof Date) ?
                    (repaymentScheduleDateBefore as any).toISOString().substr(0,10) :
                    repaymentScheduleDateBefore;
            }

            if (bindingTypes) {
                localVarQueryParameter['bindingTypes'] = bindingTypes;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideFreeTransaction: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling hideFreeTransaction.');
            }
            const localVarPath = `/api/transactions/{id}/hideFreeTransaction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConnectTransactionRequest} connectTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectFreeTransactions(connectTransactionRequest: ConnectTransactionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransactionControllerApiAxiosParamCreator(configuration).connectFreeTransactions(connectTransactionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayFreeTransaction(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransactionControllerApiAxiosParamCreator(configuration).displayFreeTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await TransactionControllerApiAxiosParamCreator(configuration).getTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyIdentity] 
         * @param {string} [search] 
         * @param {string} [transactionId] 
         * @param {number} [amountFrom] 
         * @param {number} [amountTo] 
         * @param {number} [loanId] 
         * @param {boolean} [showFreeTransactions] 
         * @param {Array<TransactionStatus>} [statuses] 
         * @param {Array<TransactionType>} [types] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [repaymentScheduleDateAfter] 
         * @param {string} [repaymentScheduleDateBefore] 
         * @param {Array<TransactionBindingType>} [bindingTypes] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, companyName?: string, companyIdentity?: string, search?: string, transactionId?: string, amountFrom?: number, amountTo?: number, loanId?: number, showFreeTransactions?: boolean, statuses?: Array<TransactionStatus>, types?: Array<TransactionType>, updatedAtAfter?: string, updatedAtBefore?: string, repaymentScheduleDateAfter?: string, repaymentScheduleDateBefore?: string, bindingTypes?: Array<TransactionBindingType>, comment?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelTransactionListItem>> {
            const localVarAxiosArgs = await TransactionControllerApiAxiosParamCreator(configuration).getTransactions(page, size, sort, applicationNumber, companyName, companyIdentity, search, transactionId, amountFrom, amountTo, loanId, showFreeTransactions, statuses, types, updatedAtAfter, updatedAtBefore, repaymentScheduleDateAfter, repaymentScheduleDateBefore, bindingTypes, comment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideFreeTransaction(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TransactionControllerApiAxiosParamCreator(configuration).hideFreeTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ConnectTransactionRequest} connectTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectFreeTransactions(connectTransactionRequest: ConnectTransactionRequest, options?: any): AxiosPromise<void> {
            return TransactionControllerApiFp(configuration).connectFreeTransactions(connectTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayFreeTransaction(id: number, options?: any): AxiosPromise<void> {
            return TransactionControllerApiFp(configuration).displayFreeTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(id: number, options?: any): AxiosPromise<Transaction> {
            return TransactionControllerApiFp(configuration).getTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [applicationNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyIdentity] 
         * @param {string} [search] 
         * @param {string} [transactionId] 
         * @param {number} [amountFrom] 
         * @param {number} [amountTo] 
         * @param {number} [loanId] 
         * @param {boolean} [showFreeTransactions] 
         * @param {Array<TransactionStatus>} [statuses] 
         * @param {Array<TransactionType>} [types] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [repaymentScheduleDateAfter] 
         * @param {string} [repaymentScheduleDateBefore] 
         * @param {Array<TransactionBindingType>} [bindingTypes] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(page?: number, size?: number, sort?: Array<string>, applicationNumber?: string, companyName?: string, companyIdentity?: string, search?: string, transactionId?: string, amountFrom?: number, amountTo?: number, loanId?: number, showFreeTransactions?: boolean, statuses?: Array<TransactionStatus>, types?: Array<TransactionType>, updatedAtAfter?: string, updatedAtBefore?: string, repaymentScheduleDateAfter?: string, repaymentScheduleDateBefore?: string, bindingTypes?: Array<TransactionBindingType>, comment?: string, options?: any): AxiosPromise<PagedModelEntityModelTransactionListItem> {
            return TransactionControllerApiFp(configuration).getTransactions(page, size, sort, applicationNumber, companyName, companyIdentity, search, transactionId, amountFrom, amountTo, loanId, showFreeTransactions, statuses, types, updatedAtAfter, updatedAtBefore, repaymentScheduleDateAfter, repaymentScheduleDateBefore, bindingTypes, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideFreeTransaction(id: number, options?: any): AxiosPromise<void> {
            return TransactionControllerApiFp(configuration).hideFreeTransaction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for connectFreeTransactions operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiConnectFreeTransactionsRequest
 */
export interface TransactionControllerApiConnectFreeTransactionsRequest {
    /**
     * 
     * @type {ConnectTransactionRequest}
     * @memberof TransactionControllerApiConnectFreeTransactions
     */
    readonly connectTransactionRequest: ConnectTransactionRequest
}

/**
 * Request parameters for displayFreeTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiDisplayFreeTransactionRequest
 */
export interface TransactionControllerApiDisplayFreeTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiDisplayFreeTransaction
     */
    readonly id: number
}

/**
 * Request parameters for getTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetTransactionRequest
 */
export interface TransactionControllerApiGetTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiGetTransaction
     */
    readonly id: number
}

/**
 * Request parameters for getTransactions operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiGetTransactionsRequest
 */
export interface TransactionControllerApiGetTransactionsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly applicationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly companyName?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly companyIdentity?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly transactionId?: string

    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly amountFrom?: number

    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly amountTo?: number

    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly loanId?: number

    /**
     * 
     * @type {boolean}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly showFreeTransactions?: boolean

    /**
     * 
     * @type {Array<TransactionStatus>}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly statuses?: Array<TransactionStatus>

    /**
     * 
     * @type {Array<TransactionType>}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly types?: Array<TransactionType>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly updatedAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly updatedAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly repaymentScheduleDateAfter?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly repaymentScheduleDateBefore?: string

    /**
     * 
     * @type {Array<TransactionBindingType>}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly bindingTypes?: Array<TransactionBindingType>

    /**
     * 
     * @type {string}
     * @memberof TransactionControllerApiGetTransactions
     */
    readonly comment?: string
}

/**
 * Request parameters for hideFreeTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiHideFreeTransactionRequest
 */
export interface TransactionControllerApiHideFreeTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionControllerApiHideFreeTransaction
     */
    readonly id: number
}

/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
    /**
     * 
     * @param {TransactionControllerApiConnectFreeTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public connectFreeTransactions(requestParameters: TransactionControllerApiConnectFreeTransactionsRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).connectFreeTransactions(requestParameters.connectTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiDisplayFreeTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public displayFreeTransaction(requestParameters: TransactionControllerApiDisplayFreeTransactionRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).displayFreeTransaction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiGetTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getTransaction(requestParameters: TransactionControllerApiGetTransactionRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).getTransaction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiGetTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public getTransactions(requestParameters: TransactionControllerApiGetTransactionsRequest = {}, options?: any) {
        return TransactionControllerApiFp(this.configuration).getTransactions(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.applicationNumber, requestParameters.companyName, requestParameters.companyIdentity, requestParameters.search, requestParameters.transactionId, requestParameters.amountFrom, requestParameters.amountTo, requestParameters.loanId, requestParameters.showFreeTransactions, requestParameters.statuses, requestParameters.types, requestParameters.updatedAtAfter, requestParameters.updatedAtBefore, requestParameters.repaymentScheduleDateAfter, requestParameters.repaymentScheduleDateBefore, requestParameters.bindingTypes, requestParameters.comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionControllerApiHideFreeTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    public hideFreeTransaction(requestParameters: TransactionControllerApiHideFreeTransactionRequest, options?: any) {
        return TransactionControllerApiFp(this.configuration).hideFreeTransaction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UiSettingsControllerApi - axios parameter creator
 * @export
 */
export const UiSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiSettingsControllerApi - functional programming interface
 * @export
 */
export const UiSettingsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiSettings>> {
            const localVarAxiosArgs = await UiSettingsControllerApiAxiosParamCreator(configuration).getSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UiSettingsControllerApi - factory interface
 * @export
 */
export const UiSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<UiSettings> {
            return UiSettingsControllerApiFp(configuration).getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiSettingsControllerApi - object-oriented interface
 * @export
 * @class UiSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UiSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiSettingsControllerApi
     */
    public getSettings(options?: any) {
        return UiSettingsControllerApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }
}



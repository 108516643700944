import Keycloak from 'keycloak-js';
import { CheckSSOKeycloakBehaviorStrategy } from 'src/stores/AuthStore/CheckSSOKeycloakBehaviorStrategy';
import {
    KC_AUTH_TOKENS,
    KeycloakAuthTokens,
    KeycloakBehaviorStrategy,
} from 'src/stores/AuthStore/KeycloakBehaviorStrategy';
import { TokensKeycloakBehaviorStrategy } from 'src/stores/AuthStore/TokensKeycloakBehaviorStrategy';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import { TokenHelper } from 'src/utils/TokenHelper';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh-token';

export class KeycloakBehaviorManager {
    constructor(private keycloak: Keycloak) {
        this.storeTokensIfPassedInFragmentUrl();
    }

    getKeycloakBehaviorStrategy(): KeycloakBehaviorStrategy {
        if (this.canUseTokenAndRefreshTokenStrategy()) {
            return new TokensKeycloakBehaviorStrategy(this.keycloak);
        }

        return new CheckSSOKeycloakBehaviorStrategy(this.keycloak);
    }

    storeTokensIfPassedInFragmentUrl() {
        const params = new URLSearchParams(window.location.hash.slice(1));

        const token = params.get(TOKEN_KEY);
        const refreshToken = params.get(REFRESH_TOKEN_KEY);

        if (token && refreshToken) {
            LocalStoreHelper.setItem(KC_AUTH_TOKENS, { token, refreshToken });
        }
    }

    private canUseTokenAndRefreshTokenStrategy() {
        const tokens =
            LocalStoreHelper.getItem<KeycloakAuthTokens>(KC_AUTH_TOKENS);

        return TokenHelper.isValidToken(tokens?.refreshToken);
    }
}

import Keycloak from 'keycloak-js';

export const KC_AUTH_TOKENS = 'KC_AUTH_TOKENS';

export type KeycloakAuthTokens = {
    token?: string;
    refreshToken?: string;
};

export abstract class KeycloakBehaviorStrategy {
    abstract authenticate(): Promise<boolean>;
    abstract logout(): Promise<void>;

    constructor(public keycloak: Keycloak) {}
}

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { BasePage } from 'src/components/BasePage/BasePage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';

export const IndexPage = observer(() => {
    const { t } = useTranslation();
    if (AuthStore.authenticated) {
        return <Navigate to={RoutePaths.loans} replace />;
    }

    return <BasePage title={t('Page.Index.Title')} />;
});

/* eslint-disable camelcase */
import { IAuthStore } from 'src/stores/AuthStore/AuthStore.types';

import { AuthStoreKeycloakClass } from './AuthStoreKeycloak';

const getAuthStore = (): IAuthStore => {
    return new AuthStoreKeycloakClass();
};

export const AuthStore = getAuthStore();

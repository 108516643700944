import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect } from 'react';
import { BasePage, BasePageProps } from 'src/components/BasePage/BasePage';
import { ListView, ListViewProps } from 'src/components/ListView/ListView';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { Entity } from 'src/utils/mobx/BasicStore/BasicStore.types';
import styled from 'styled-components';

export type ListPageProps<T extends Entity> = {
    store: BasicStore<T>;
    columns: ListViewProps<T>['columns'];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRowClick?: (e: MouseEvent, record: T) => void;
    listViewProps?: Omit<ListViewProps<T>, 'store'>;
    beforeLoadList?: () => void;
} & BasePageProps;

export const ListPage = observer(
    ({
        store,
        columns,
        onRowClick,
        listViewProps,
        beforeLoadList,
        ...props
    }: ListPageProps<any>) => {
        useEffect(() => {
            beforeLoadList?.();
            store.loadList();

            return () => {
                store.listLoader.reset();
                store.filterCriteria?.resetFilter();
            };
        }, []);

        return (
            <StyledBasePage {...props} isLoading={store.listLoader.isLoading}>
                <ListView
                    {...listViewProps}
                    store={store}
                    columns={columns}
                    onRowClick={onRowClick}
                />
            </StyledBasePage>
        );
    },
);

const StyledBasePage = styled(BasePage)`
    padding: 24px;
`;

import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { Router } from 'src/core/router/Router';
import { configureMobX } from 'src/mobXSettings';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';

export class AppStoreClass {
    @observable isSetHistory = true;
    @observable isInit = false;
    @observable isLoading = false;

    @action
    async init() {
        configureMobX();
        await SettingsStore.load();

        await SettingsStore.setupInternationalization();

        await AuthStore.init?.();
        await AuthStore.authenticate();

        if (AuthStore.authenticated) {
            await AppUser.loadUserInfo();
        }

        this.isInit = true;
    }

    @computed get started() {
        return this.isSetHistory && this.isInit;
    }

    setHistory(history: History) {
        Router.setHistory(history);
        this.isSetHistory = true;
    }

    @action startLoading() {
        this.isLoading = true;
    }

    @action stopLoading() {
        this.isLoading = false;
    }
}

export const AppStore = new AppStoreClass();

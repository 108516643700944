import styled from 'styled-components';

export const Logo = () => {
    return <LogoWrapper>EvaBeta</LogoWrapper>;
};

const LogoWrapper = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

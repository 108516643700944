import { History, State } from 'history';
import { NavigateFunction } from 'react-router-dom';
import { UrlHelper } from 'src/utils/UrlHelper';

// eslint-disable-next-line no-underscore-dangle, init-declarations
let _history: History;

const setHistory = (history: History) => {
    _history = history;
};

// eslint-disable-next-line no-underscore-dangle
let _navigate: NavigateFunction;

const setNavigateFn = (navigateFn: NavigateFunction) => {
    _navigate = navigateFn;
};

const navigate = (url: string, state?: any) => {
    _navigate(url, {
        state,
    });
    // redirect(url);
    // _history.push(url, state);
};

const replace = (url: string, state?: State) => {
    _navigate(url, {
        state,
        replace: true,
    });
};

const applySearchParams = (searchParams: Record<string, any>) => {
    const currentSearch = UrlHelper.parseSearchParams(window.location.search);
    const newSearch = UrlHelper.stringifySearchParams({
        ...currentSearch,
        ...searchParams,
    });
    _navigate(`${window.location.pathname}?${newSearch}`);
};

const setSearchParams = (searchParams: Record<string, any>) => {
    const newSearch = UrlHelper.stringifySearchParams(searchParams);
    _navigate(`${window.location.pathname}?${newSearch}`);
};

export const Router = {
    setNavigateFn,
    setHistory,
    navigate,
    replace,
    applySearchParams,
    setSearchParams,
};

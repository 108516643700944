import { action, observable } from 'mobx';
import { DataIndex, getPropertyPathAsProp } from 'src/utils/ObjectHelper';

export type SortOrder = 'ascend' | 'descend' | null;
export type SortField = {
    field: DataIndex;
    order: SortOrder;
};

export class Sorter<T = any> {
    @observable order?: SortOrder;
    @observable field?: keyof T | Array<keyof T>;
    @observable fields?: SortField[];

    constructor(private defaultFields?: SortField[]) {
        this.reset();
    }

    @action setSorter(fields?: SortField[]) {
        this.fields = fields;
    }

    @action reset() {
        this.setSorter(this.defaultFields);
    }

    getSort() {
        if (!this.fields || this.fields.length === 0) {
            return undefined;
        }

        return this.fields.map((field) => {
            return [
                getPropertyPathAsProp(field.field),
                getServerSortOrder(field.order),
            ].join(',');
        });
    }
}

const SorterDirectionToServerSortDirectionMap = {
    ascend: 'ASC',
    descend: 'DESC',
};

const getServerSortOrder = (order: SortOrder) => {
    return SorterDirectionToServerSortDirectionMap[
        order as keyof typeof SorterDirectionToServerSortDirectionMap
    ];
};

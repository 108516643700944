import { DatePicker as AntDatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';

export const DateRangePicker = (props: RangePickerProps) => {
    const { t } = useTranslation();

    return (
        <AntDatePicker.RangePicker
            placeholder={[
                t('Component.DateRangePicker.Start'),
                t('Component.DateRangePicker.End'),
            ]}
            {...props}
        />
    );
};
